import { Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import { BrandDivider } from "components/Miscellaneous/Brand";
import { useEffect, useState } from "react";

export default function ScramblerModal({ isOpen, onOpen, onClose, onUpload, filename }) {
  const [batches, setBatches] = useState("");
  const [batchesError, setBatchesError] = useState(null);

  const handleInputChangeBatches = (event) => {
    setBatches(event.target.value);
  };

  const validateBatches = () => {
    if (batches.trim() === "") {
      setBatchesError("Batches cannot be empty");
    } else if (batches.trim() === "0") {
      setBatchesError("Batches must be more than 0");
    } else {
      setBatchesError(null);
    }
  };

  useEffect(() => {
    setBatches("");
    setBatchesError(null);
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={0}>Upload file</ModalHeader>
        <ModalCloseButton />
        <ModalBody mb={4}>
          <Text marginBottom={4}>Update settings for processing.</Text>
          <BrandDivider />
          <FormControl mt={4} mb={4}>
            <FormLabel>File</FormLabel>
            <Input type="text" value={filename} size="md" readOnly disabled />
          </FormControl>
          <FormControl isInvalid={batchesError !== null} id="group-input" mt={4} mb={4}>
            <FormLabel>Batches</FormLabel>
            <Input type="number" value={batches} onChange={handleInputChangeBatches} onBlur={validateBatches} placeholder="Number of batches" size="md" autoFocus />
            <FormErrorMessage>{batchesError}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button onClick={onClose} variant={"secondary"}>
            Cancel
          </Button>
          <Button onClick={() => onUpload(batches)} colorScheme="brand">
            Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
