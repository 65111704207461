import { Box, Divider, Heading, Text } from "@chakra-ui/react";

export function BrandCard({ title, description, children }) {
  if (description) {
    return (
      <Box p={5} shadow="md" borderRadius={8} backgroundColor={"white"} width={"100%"} position={"relative"}>
        <Heading fontSize="2xl">{title}</Heading>
        <Text mt={2}>{description}</Text>
        {children && <BrandDivider />}
        {children}
      </Box>
    );
  } else {
    return (
      <Box p={5} shadow="md" borderRadius={8} backgroundColor={"white"} width={"100%"}>
        <Heading fontSize="2xl">{title}</Heading>
        <Box mt={2}>{children}</Box>
      </Box>
    );
  }
}

export function BrandDivider(props) {
  return <Divider marginY={4} borderWidth={1} borderColor={"gray.300"} {...props} />;
}

export function CardDivider(props) {
  return <Divider marginY={0} borderWidth={1} borderColor={"gray.300"} {...props} />;
}
