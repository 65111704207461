import * as React from "react";

const EniroSVG = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" {...props}>
    <path d="M200 0h644a180 180 0 0 1 180 180v664a180 180 0 0 1-180 180H180A180 180 0 0 1 0 844V200A200 200 0 0 1 200 0Z" fill="#ffdf00" />
    <path d="M489 214.25c-1.9.2-7.8.9-13 1.5-125.2 14.3-230.5 111-255.9 235-4.7 22.6-5.5 32.3-5.5 60.9s.8 38.3 5.5 60.9c11.7 57.1 41.2 110.8 83.6 152.2 52.8 51.5 121.3 81.4 194.1 84.6l14.2.6v-106.9l-14.2-1.2c-48.9-4.1-89.6-23.4-123.3-58.3-27-28-43.5-60.6-50.7-99.9-3-16.4-3-47.6 0-64 7.2-39.3 23.7-71.9 50.7-99.9 16.9-17.5 33.5-29.7 54-39.7a191.86 191.86 0 0 1 164.5-1.2c39.8 18.7 74.4 53.5 92.5 93.3 2.9 6.3 9.5 23.8 9.5 25.1 0 .2-53.2.5-118.2.6l-118.3.3v107h347l1.8-11.5c2.7-16.8 2.9-64.6.4-81.4-3.8-25.1-8.7-44.7-16.4-65.1a299.15 299.15 0 0 0-174.8-174.8c-33.2-12.5-61.5-17.7-99.5-18.2-13.53-.15-26.13-.1-28 .1Z" />
  </svg>
);

export default EniroSVG;
