import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BrandDivider } from "components/Miscellaneous/Brand";
import dayjs from "dayjs";
import { updateDoc } from "firebase/firestore";
import { useEffect } from "react";

export default function ManageFilesModal({ isOpen, onOpen, onClose, config, currentUser, files }) {
  const disabled = files.some((file) => file.Delete || file.Processing);

  useEffect(() => {
    onClose();
    // eslint-disable-next-line
  }, [files]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered closeOnEsc={!disabled} closeOnOverlayClick={!disabled} size={"5xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={0}>Manage files</ModalHeader>
        <ModalCloseButton isDisabled={disabled} />
        <ModalBody mb={4}>
          <BrandDivider />
          <Table variant="striped" margin={"auto"}>
            <Thead>
              <Tr>
                <Th>Filename</Th>
                <Th>Uploaded by</Th>
                <Th>Uploaded at</Th>
                {currentUser.isAdmin && <Th>Action</Th>}
              </Tr>
            </Thead>
            <Tbody>
              {files.map(({ Filename, UploadedBy, UploadedAt, ref }) => (
                <Tr key={UploadedAt}>
                  <Td>
                    <Text fontSize={"sm"} fontWeight={"bold"}>
                      {Filename}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize={"sm"} fontWeight={"bold"}>
                      {UploadedBy}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize={"sm"} fontWeight={"bold"}>
                      {UploadedAt?.seconds ? dayjs.unix(UploadedAt?.seconds).format("YYYY-MM-DD HH:mm:ss") : "-"}
                    </Text>
                  </Td>
                  {currentUser.isAdmin && (
                    <Td>
                      <Button
                        variant={"danger"}
                        size={"sm"}
                        iconSpacing={3}
                        rightIcon={<FontAwesomeIcon icon={faTrash} />}
                        isDisabled={!currentUser.isAdmin || disabled}
                        onClick={currentUser.isAdmin ? () => updateDoc(ref, { Processing: true, Delete: true }) : null}>
                        Delete
                      </Button>
                    </Td>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button onClick={onClose} isDisabled={disabled} isLoading={disabled} variant={"secondary"}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
