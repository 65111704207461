import { Box, Button, Flex, HStack, Heading, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack, useDisclosure } from "@chakra-ui/react";
import { faFileInvoice, faMaximize, faWallet } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { calculateEniroCost, calculateHittaCost } from "api/cost";
import { accentgrey, grey, lightgrey, white } from "colors";
import { CardDivider } from "components/Miscellaneous/Brand";
import { Card, HorizontalIconCard } from "components/Miscellaneous/Cards";
import dayjs from "dayjs";
import { collection, getFirestore, onSnapshot } from "firebase/firestore";
import Loading from "pages/Miscellaneous/Loading";
import { useEffect, useState } from "react";
import { COLLECTION } from "../../data/enum";
import InvoiceModal from "./Modal/InvoiceModal";

export default function Invoices(props) {
  const [invoices, setInvoices] = useState([]);
  const [isLoadingSnapshot, setIsLoadingSnapshot] = useState(true);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const invoiceDisclosure = useDisclosure();

  const { config } = props;

  useEffect(() => {
    if (selectedInvoice) {
      invoiceDisclosure.onOpen();
    }
    // eslint-disable-next-line
  }, [selectedInvoice]);

  useEffect(() => {
    setIsLoadingSnapshot(true);

    const db = getFirestore();
    const invoicesCollection = collection(db, COLLECTION.ORGANIZATIONS, config.ID, COLLECTION.INVOICES);
    const subscription = onSnapshot(invoicesCollection, (invoicesListSnapshot) => {
      const sortedInvoices = invoicesListSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => b.Timestamp.toMillis() - a.Timestamp.toMillis());

      setInvoices(sortedInvoices);
      setIsLoadingSnapshot(false);
    });

    return subscription;
    // eslint-disable-next-line
  }, []);

  // The group has not been fully setup by the function yet
  if (!invoices) {
    return <Loading />;
  }

  const invoicesTotal = invoices.reduce((acc, obj) => {
    let adjustment = obj?.Adjusted ?? 1;
    let eniroRevenue = calculateEniroCost(obj.Eniro);
    let hittaRevenue = calculateHittaCost(obj.Hitta);
    let totalRevenue = eniroRevenue + hittaRevenue;
    let totalMarginRevenue = Math.ceil(totalRevenue * 1.1 * adjustment);
    return acc + totalMarginRevenue;
  }, 0);

  return (
    <>
      <>
        <InvoiceModal {...invoiceDisclosure} {...props} invoice={selectedInvoice} />
      </>
      <VStack padding={8} width={"100%"} gap={4} alignItems={"flex-start"}>
        <VStack width={"100%"} alignItems={"flex-start"} justifyContent={"flex-start"}>
          <HStack justifyContent={"space-between"} alignItems={"flex-start"} width={"100%"}>
            <VStack alignItems={"flex-start"} justifyContent={"flex-start"}>
              <Heading size={"lg"}>Invoices</Heading>
              <Text>Invoices generated for this organization</Text>
            </VStack>
          </HStack>
        </VStack>
        <CardDivider />
        <Card title={"Summary"}>
          <HStack width={"100%"} justifyContent={"space-evenly"}>
            <HorizontalIconCard title={"Invoices"} icon={faFileInvoice}>
              <HStack alignItems={"flex-end"}>
                <Text fontSize={"2xl"} as={"b"}>
                  {invoices.length}
                </Text>
                <Text fontSize={"mg"} paddingBottom={1} color={accentgrey}>
                  (total)
                </Text>
              </HStack>
            </HorizontalIconCard>

            <HorizontalIconCard title={"Revenue"} icon={faWallet}>
              <HStack alignItems={"flex-end"}>
                <Text fontSize={"2xl"} as={"b"}>
                  {invoicesTotal}
                </Text>
                <Text fontSize={"mg"} paddingBottom={1} color={accentgrey}>
                  (SEK)
                </Text>
              </HStack>
            </HorizontalIconCard>
          </HStack>
        </Card>
        <CardDivider />

        {invoices.length === 0 || isLoadingSnapshot ? (
          <Flex
            borderColor="gray.300"
            borderStyle="dashed"
            borderWidth="2px"
            rounded="md"
            height={150}
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}>
            <VStack gap={2}>
              <Text as={"b"}>{isLoadingSnapshot ? <Spinner size={"lg"} /> : "No invoices yet 🙁"}</Text>
            </VStack>
          </Flex>
        ) : (
          <Box width={"100%"} backgroundColor={white} borderRadius={10} borderColor={lightgrey} borderWidth={2}>
            <TableContainer width={"100%"}>
              <Table variant="striped">
                <Thead>
                  <Tr>
                    <Th>ID</Th>
                    <Th>Month</Th>
                    <Th>Date generated</Th>
                    <Th>Requests</Th>
                    <Th>Total</Th>
                    <Th textAlign={"flex-end"}>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {invoices.map((item, index) => {
                    const adjustment = item?.Adjusted ?? 1;
                    const eniroCost = calculateEniroCost(item.Eniro);
                    const hittaCost = calculateHittaCost(item.Hitta);
                    const totalCost = eniroCost + hittaCost;
                    const totalMarginCost = Math.ceil(totalCost * 1.1 * adjustment);

                    return (
                      <Tr key={item.id}>
                        <Td paddingY={4}>
                          <Flex direction={"column"} justifyContent={"center"} alignItems={"flex-start"}>
                            <Text fontSize={"sm"} color={grey}>
                              {`#-${(invoices.length - index - 1).toString().padStart(3, "0")}`}
                            </Text>
                          </Flex>
                        </Td>
                        <Td paddingY={4}>
                          <Flex direction={"column"} justifyContent={"center"} alignItems={"flex-start"}>
                            <Text fontSize={"md"} as={"b"}>
                              {dayjs(item.Timestamp.toDate()).subtract(1, "month").format("MMMM")}
                            </Text>
                            <Text fontSize={"sm"} color={grey}>
                              invoice
                            </Text>
                          </Flex>
                        </Td>
                        <Td paddingY={4}>
                          <Flex direction={"column"} justifyContent={"center"} alignItems={"flex-start"}>
                            <Text fontSize={"md"} as={"b"}>
                              {dayjs(item.Timestamp.toDate()).format("YYYY-MM-DD")}
                            </Text>
                            <Text fontSize={"sm"} color={grey}>
                              {dayjs(item.Timestamp.toDate()).format("MMMM")}
                            </Text>
                          </Flex>
                        </Td>
                        <Td paddingY={4}>
                          <Flex direction={"column"} justifyContent={"center"} alignItems={"flex-start"}>
                            <Text fontSize={"md"} as={"b"}>
                              {item.Eniro + item.Hitta + item.Hundra}
                            </Text>
                            <Text fontSize={"sm"} color={grey}>
                              Total requests
                            </Text>
                          </Flex>
                        </Td>
                        <Td paddingY={4}>
                          <Flex direction={"column"} justifyContent={"center"} alignItems={"flex-start"}>
                            <Text fontSize={"md"} as={"b"}>
                              {totalMarginCost}
                            </Text>
                            <Text fontSize={"sm"} color={grey}>
                              SEK
                            </Text>
                          </Flex>
                        </Td>
                        <Td paddingY={4}>
                          <Flex justifyContent={"flex-end"}>
                            <Button
                              colorScheme="brand"
                              iconSpacing={3}
                              leftIcon={<FontAwesomeIcon icon={faMaximize} />}
                              onClick={() => setSelectedInvoice({ ...item, index: invoices.length - index - 1 })}>
                              View
                            </Button>
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </VStack>
    </>
  );
}
