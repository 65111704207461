import {
  Box,
  Button,
  Code,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { LogActivityOrganization } from "api/firebase";
import { ACTIVITY, COLLECTION } from "data/enum";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database";
import { addDoc, collection, getFirestore, serverTimestamp, updateDoc } from "firebase/firestore";
import { parse } from "papaparse";
import { useEffect } from "react";
import FileDropper from "../../Miscellaneous/FileDropper";

export default function UploadFilesModal({ Status, isOpen, onOpen, onClose, config, groupId, numbers, currentUser }) {
  const [isLoading, setLoading] = useBoolean();

  const toast = useToast();

  function processCSV(file) {
    const filename = file?.name;

    const failed = (message = "Could not process file!") => {
      setLoading.off();

      toast({
        title: message,
        status: "error",
        duration: 2500,
        isClosable: false,
      });
    };

    if (!filename?.toLowerCase().endsWith(".csv")) {
      failed();
      return;
    }

    setLoading.on();

    parse(file, {
      header: true,
      complete: async (results, _file) => {
        const { data } = results;

        const filteredRows = data.filter(
          (row) =>
            row?.callingmask &&
            row?.contacted_local_criteria &&
            row?.start_call_datetime &&
            row?.end_call_datetime &&
            row?.zip_code &&
            row?.start_call_datetime &&
            row?.end_call_datetime
        );

        if (filteredRows.length === 0) {
          failed("Could not process file! No valid phonecalls.");
          return;
        }

        const mappedRows = filteredRows.map(({ callingmask, contacted_local_criteria, start_call_datetime, end_call_datetime, zip_code }) => ({
          callingmask,
          contacted_local_criteria,
          contacted: contacted_local_criteria.toUpperCase() === "TRUE",
          start_call_datetime,
          end_call_datetime,
          zip_code: zip_code.replace(/\s+/g, ""),
        }));

        const { earliestStartTime, latestEndTime } = mappedRows.reduce(
          (acc, current) => {
            // Check if the current start time is earlier than the stored earliest start time
            if (new Date(current.start_call_datetime) < new Date(acc.earliestStartTime)) {
              acc.earliestStartTime = current.start_call_datetime;
            }

            // Check if the current end time is later than the stored latest end time
            if (new Date(current.end_call_datetime) > new Date(acc.latestEndTime)) {
              acc.latestEndTime = current.end_call_datetime;
            }

            return acc;
          },
          {
            earliestStartTime: new Date(mappedRows[0].start_call_datetime),
            latestEndTime: new Date(mappedRows[0].end_call_datetime),
          }
        );

        const db = getFirestore();
        const database = getDatabase();

        const auth = getAuth();
        const currentUserEmail = auth.currentUser.email;

        const filesCollectionRef = collection(db, COLLECTION.ORGANIZATIONS, config.ID, COLLECTION.FILES);

        const docRef = await addDoc(filesCollectionRef, {
          Filename: filename,
          TimespanStart: earliestStartTime,
          TimespanEnd: latestEndTime,
          UploadedBy: currentUserEmail,
          UploadedAt: serverTimestamp(),
          Processing: true,
          Process: false,
        });

        const callsCollectionRef = ref(database, config.ID + "/files/" + docRef.id);
        await set(callsCollectionRef, mappedRows);

        await updateDoc(docRef, { Process: true });

        toast({
          title: "File uploaded",
          status: "success",
          duration: 2500,
          isClosable: false,
        });

        LogActivityOrganization(config.ID, ACTIVITY.ACTION.ADDED, ACTIVITY.TYPE.FILES, { Filename: filename, Calls: mappedRows.length }, currentUser);

        setLoading.off();
        onClose();
      },
      error: (error, file) => {
        console.error(error);
        failed();
      },
    });
  }

  useEffect(() => {
    onClose();
    // eslint-disable-next-line
  }, [Status]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered closeOnEsc={!isLoading} closeOnOverlayClick={!isLoading}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={0}>Import phone calls</ModalHeader>
        <ModalCloseButton isDisabled={isLoading} />
        <ModalBody>
          <Text marginBottom={4}>
            Upload an .csv file. The file should contains rows <Code>callingmask</Code>,<Code>contacted_local_criteria</Code>,<Code>start_call_datetime</Code>,
            <Code>end_call_datetime</Code>,<Code>zip_code</Code>.
          </Text>
          <Box position={"relative"}>
            <Box pointerEvents={isLoading ? "none" : null} opacity={isLoading ? 0.25 : 1}>
              <FileDropper filetype={".csv"} onSelected={processCSV} />
            </Box>
            {isLoading && (
              <Box position={"absolute"} top={0} right={0} bottom={0} left={0} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Spinner label="Loading" size={"xl"} />
              </Box>
            )}
          </Box>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button onClick={onClose} isDisabled={isLoading} variant={"secondary"}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
