import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useBoolean, useToast } from "@chakra-ui/react";
import { LogActivityGroup } from "api/firebase";
import { doc, getFirestore, writeBatch } from "firebase/firestore";
import { useEffect } from "react";
import { ACTIVITY, COLLECTION } from "../../../data/enum";

export default function DeleteNumbersModal({ Status, config, groupId, numbers, isOpen, onOpen, onClose, setIsEditingNumbers, currentUser }) {
  const [isLoading, setLoading] = useBoolean();

  const toast = useToast();

  function deleteNumbers() {
    setLoading.on();

    const db = getFirestore();

    const batch = writeBatch(db);
    for (let number of numbers) {
      let docRef = doc(db, COLLECTION.ORGANIZATIONS, config.ID, COLLECTION.GROUPS, groupId, COLLECTION.NUMBERS, number);
      batch.delete(docRef);
    }
    batch
      .commit()
      .then((doc) => {
        onClose();

        toast({
          title: `Deleted ${numbers.length} number${numbers.length > 1 ? "s" : ""}`,
          status: "success",
          duration: 2500,
          isClosable: false,
        });

        LogActivityGroup(config.ID, groupId, ACTIVITY.ACTION.DELETED, ACTIVITY.TYPE.NUMBER, numbers, currentUser);
      })
      .catch((e) => {
        console.error(e);

        toast({
          title: "Failed to delete numbers",
          status: "error",
          duration: 2500,
          isClosable: false,
        });
      })
      .finally(() => {
        setIsEditingNumbers((isEditingNumbers) => !isEditingNumbers);
        setLoading.off();
      });
  }

  useEffect(() => {
    onClose();
    // eslint-disable-next-line
  }, [Status]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered closeOnEsc={!isLoading} closeOnOverlayClick={!isLoading}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={0}>{numbers.length > 1 ? `Delete numbers` : "Delete number"}?</ModalHeader>
        <ModalCloseButton isDisabled={isLoading} />
        <ModalBody mb={4}>
          <Text>
            Are you sure that you want to delete {numbers.length > 1 ? "these" : "this"} number{numbers.length > 1 ? "s" : ""}?
          </Text>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button onClick={onClose} isDisabled={isLoading} variant={"secondary"}>
            Cancel
          </Button>
          <Button onClick={deleteNumbers} isLoading={isLoading} variant={"danger"}>
            {numbers.length > 1 ? `Delete ${numbers.length} numbers` : "Delete number"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
