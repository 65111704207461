import { Flex, ScaleFade, Text } from "@chakra-ui/react";
import { faTrafficCone } from "@fortawesome/pro-duotone-svg-icons";
import { VerticalIconCard } from "components/Miscellaneous/Cards";

export default function Unavailable() {
  return (
    <Flex minH={"100vh"} width={"100%"} alignItems={"center"} justifyContent={"center"}>
      <ScaleFade initialScale={0.9} in={true}>
        <VerticalIconCard title={"Unavailable"} icon={faTrafficCone} style={{ "--fa-primary-color": "#ea862a", "--fa-secondary-color": "#ea862a" }}>
          <Text fontSize={"lg"}>This page is currently unavailable</Text>
        </VerticalIconCard>
      </ScaleFade>
    </Flex>
  );
}
