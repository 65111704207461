export function numberGroupSort(numberGroup) {
  return numberGroup.sort((a, b) => {
    // Both 'CreatedAt' properties exist, so compare them directly
    if (a.CreatedAt && b.CreatedAt) {
      return a.CreatedAt - b.CreatedAt;
    }
    // Only 'a.CreatedAt' exists, so 'a' goes first
    else if (a.CreatedAt) {
      return -1;
    }
    // Only 'b.CreatedAt' exists, so 'b' goes first
    else if (b.CreatedAt) {
      return 1;
    }
    // Neither 'CreatedAt' exists, so they're considered equal
    else {
      return 0;
    }
  });
}
