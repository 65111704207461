import { Box, CircularProgress, Fade, Flex, Heading, Input, SlideFade, Stack, Text, VStack, useDisclosure, useToast } from "@chakra-ui/react";
import { faFileUpload } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { flattenArray } from "api/array";
import { lightgrey } from "colors";
import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { parse } from "papaparse";
import { useState } from "react";
import ScramblerModal from "./Modal/ScramblerModal";

export default function Scrambler() {
  const uploadStages = {
    IDLE: { value: 0, label: "Upload a file", sublabel: "Drop .csv file here or click to upload", indeterminate: false },
    UPLOADING: { value: 45, label: "Uploading...", sublabel: "This might take a while", indeterminate: false },
    PROCESSING: { value: 75, label: "Processing...", sublabel: "This might take a while", indeterminate: false },
    FINIShED: { value: 100, label: "Done!", sublabel: "The file will be downloaded automatically", indeterminate: false },
  };

  const [progress, setProgress] = useState(uploadStages.IDLE);
  const [filename, setFilename] = useState("");
  const [fileResults, setFileResults] = useState([]);
  const [papaparseConfig, setPapaparseConfig] = useState({});

  const scramblerDisclosure = useDisclosure();

  const toast = useToast();

  function OnCloseModal() {
    setProgress(uploadStages.IDLE);
    scramblerDisclosure.onClose();
  }

  async function OnUpload(batches) {
    scramblerDisclosure.onClose();
    setProgress({ ...uploadStages.PROCESSING, indeterminate: true });

    const request = { numbers: fileResults, batches: Number(batches), papaparseConfig };

    const app = getApp();
    const functions = getFunctions(app, "europe-west1");
    const balancePhoneNumbers = httpsCallable(functions, "BalancePhoneNumbers", { timeout: 90000 });
    balancePhoneNumbers(request)
      .then((response) => {
        setProgress(uploadStages.FINIShED);
        window.location.href = response.data.downloadURL;
      })
      .catch((error) => {
        console.error(error);
        toast({
          title: "Could not process file!",
          status: "error",
          duration: 2500,
          isClosable: false,
        });
      });
  }

  async function processCSV(file) {
    const failed = () => {
      setProgress(uploadStages.IDLE);
      toast({
        title: "Could not process file!",
        status: "error",
        duration: 2500,
        isClosable: false,
      });
    };

    if (!file?.name?.toLowerCase().includes("csv")) {
      setProgress(uploadStages.IDLE);
      toast({
        title: "File is not .csv",
        status: "error",
        duration: 2500,
        isClosable: false,
      });
      return;
    }
    setProgress(uploadStages.UPLOADING);
    parse(file, {
      complete: (results, file) => {
        setFilename(file.name);
        setFileResults(
          flattenArray(results.data)
            .map((number) => number.trim())
            .filter((number) => number.length > 0)
        );
        setPapaparseConfig({ newline: results.meta.linebreak, delimiter: results.meta.delimiter });
        setProgress(uploadStages.PROCESSING);
        scramblerDisclosure.onOpen();
      },
      error: (error, file) => {
        console.error(error);
        failed();
      },
    });
  }

  return (
    <>
      <ScramblerModal {...scramblerDisclosure} onClose={OnCloseModal} onUpload={OnUpload} filename={filename} />
      <Flex minH={"100vh"} width={"100%"} alignItems={"center"} justifyContent={"center"} position={"relative"}>
        <CustomFileDropper progress={progress} onSelected={processCSV} />
      </Flex>
    </>
  );
}

function CustomFileDropper({ progress, onSelected }) {
  return (
    <Box cursor={progress.value === 0 ? "pointer" : "auto"} initial="rest" style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}>
      <Box position="relative" height="100%" width="100%">
        <Box position="absolute" top="0" left="0" height="100%" width="100%" display="flex" flexDirection="column">
          <Stack height="100%" width="100%" display="flex" alignItems="center" justify="center" spacing="4">
            <Stack p="8" textAlign="center" spacing="1" borderColor="gray.300" borderStyle="dashed" borderWidth="4px" rounded="md" justifyContent={"center"} alignItems={"center"} height={300} width={600} maxHeight={"100%"} maxWidth={"100%"}>
              <SlideFade in={true}>
                <VStack justifyContent={"flex-start"} alignItems={"center"}>
                  <Box position={"relative"} width={20} height={20}>
                    <Box backgroundColor={lightgrey} width={20} height={20} marginBottom={3} display={"flex"} justifyContent={"center"} alignItems={"center"} borderRadius={100} position={"absolute"} top={0} right={0} bottom={0} left={0}>
                      <FontAwesomeIcon icon={faFileUpload} size="2x" />
                    </Box>
                    <Fade in={progress.value > 0}>
                      <CircularProgress value={progress.value} isIndeterminate={progress.indeterminate} color="green.300" size={20} position={"absolute"} top={0} right={0} bottom={0} left={0} />
                    </Fade>
                  </Box>

                  <VStack alignItems={"center"} justifyContent={"center"}>
                    <Heading fontSize={"2xl"}>{progress.label}</Heading>
                    <Text fontSize={"lg"}>{progress.sublabel}</Text>
                  </VStack>
                </VStack>
              </SlideFade>
            </Stack>
          </Stack>
        </Box>
        {(progress.value === 0 || progress.value === 100) && <Input type="file" height="100%" width="100%" position="absolute" top="0" left="0" opacity="0" aria-hidden="true" onChange={(e) => onSelected(e.target.files[0])} />}
      </Box>
    </Box>
  );
}
