import { Box, Flex, HStack, Heading, Spinner, Text, VStack, keyframes } from "@chakra-ui/react";
import { faCalendar, faCloudUpload, faFile, faUser } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { black, lightgrey } from "colors";
import { useState } from "react";

export function Card({ title, text, children }) {
  return (
    <VStack alignItems={"flex-start"} width={"100%"}>
      <VStack alignItems={"flex-start"} justifyContent={"space-between"} width={"100%"} marginBottom={4}>
        <Heading as={"h2"} size={"md"}>
          {title}
        </Heading>
        <Text>{text}</Text>
      </VStack>
      <VStack alignItems={"flex-start"} width={"100%"} gap={8}>
        {children}
      </VStack>
    </VStack>
  );
}

export function CardWithHeaderElement({ title, headerChildren, children }) {
  return (
    <VStack alignItems={"flex-start"} width={"100%"}>
      <HStack alignItems={"flex-start"} justifyContent={"space-between"} width={"100%"} marginBottom={4}>
        <Heading as={"h2"} size={"md"}>
          {title}
        </Heading>
        {headerChildren}
      </HStack>
      <VStack alignItems={"flex-start"} width={"100%"} gap={8}>
        {children}
      </VStack>
    </VStack>
  );
}

export function CardWithSubHeaderElement({ title, subTitleChildren, headerChildren, children }) {
  return (
    <VStack alignItems={"flex-start"} width={"100%"}>
      <HStack alignItems={"flex-end"} justifyContent={"space-between"} width={"100%"} marginBottom={0}>
        <VStack alignItems={"flex-start"}>
          <Heading as={"h2"} size={"md"}>
            {title}
          </Heading>
          {subTitleChildren}
        </VStack>
        {headerChildren}
      </HStack>
      <VStack alignItems={"flex-start"} width={"100%"} gap={8}>
        {children}
      </VStack>
    </VStack>
  );
}

const spin = keyframes`  
  from {transform: rotate(0deg);}   
  to {transform: rotate(360deg)} 
`;
const spinAnimation = `${spin} infinite 4s linear`;

export function HorizontalIconCard({ title, icon, spinning = false, children }) {
  return (
    <HStack justifyContent={"flex-start"} alignItems={"center"} height={16}>
      <Box animation={spinning ? spinAnimation : false} backgroundColor={lightgrey} height={"100%"} marginEnd={2} style={{ aspectRatio: 1 }} display={"flex"} justifyContent={"center"} alignItems={"center"} borderRadius={100}>
        <FontAwesomeIcon icon={icon} size="2xl" color={black} />
      </Box>
      <VStack alignItems={"flex-start"} justifyContent={"center"}>
        <Heading fontSize={"lg"} marginBottom={-2}>
          {title}
        </Heading>
        {children}
      </VStack>
    </HStack>
  );
}

export function VerticalIconCard({ title, icon, style = {}, children }) {
  return (
    <VStack justifyContent={"flex-start"} alignItems={"center"}>
      <Box backgroundColor={lightgrey} width={20} height={20} marginBottom={3} display={"flex"} justifyContent={"center"} alignItems={"center"} borderRadius={100}>
        <FontAwesomeIcon icon={icon} size="3x" style={style} />
      </Box>
      <VStack alignItems={"center"} justifyContent={"center"}>
        <Heading fontSize={"2xl"}>{title}</Heading>
        {children}
      </VStack>
    </VStack>
  );
}

export function CardFileButton({ filename, uploader, startDate, endDate, uploadedDate, uploadedTime, processing }) {
  const [isHovered, setIsHovered] = useState(false); // State variable to track hover state
  const opacity = processing ? 0.4 : 1;

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Flex alignItems={"center"} flexDirection={"column"} onMouseEnter={handleHover} onMouseLeave={handleMouseLeave} paddingTop={1}>
      <Box
        position={"relative"}
        cursor={"auto"}
        style={{
          transform: isHovered ? "scale(1.025)" : "scale(1)",
          transition: "transform 0.3s ease",
        }}>
        {processing && (
          <Flex position={"absolute"} top={0} right={0} bottom={0} left={0} zIndex={1} justifyContent={"center"} alignItems={"center"}>
            <Spinner label="Loading" size={"xl"} />
          </Flex>
        )}
        <VStack style={{ opacity }} alignItems={"flex-start"} width={300} height={200} borderRadius={20} backgroundColor={lightgrey} padding={4} spacing={0} position={"relative"}>
          <Flex position={"absolute"} top={0} right={0} bottom={0} left={0} justifyContent={"center"} alignItems={"center"} style={{ opacity: 0.4 }}>
            <FontAwesomeIcon icon={faFile} size="3x" />
          </Flex>

          <VStack alignItems={"flex-start"} justifyContent={"space-between"} width={"100%"} height={"100%"} spacing={0}>
            <HStack alignItems={"center"}>
              <FontAwesomeIcon icon={faCloudUpload} size="sm" />
              <Text fontWeight={"bold"} fontSize={"sm"}>
                {uploadedDate}
              </Text>
              <Text fontSize={"sm"}>{uploadedTime}</Text>
            </HStack>
            <VStack alignItems={"flex-start"} justifyContent={"flex-end"} width={"100%"} height={"100%"} spacing={0}>
              <HStack alignItems={"center"}>
                <FontAwesomeIcon icon={faUser} size="sm" />
                <Text fontWeight={"bold"} fontSize={"sm"}>
                  {uploader}
                </Text>
              </HStack>
              <HStack alignItems={"center"}>
                <FontAwesomeIcon icon={faCalendar} size="sm" />
                <Text fontWeight={"bold"} fontSize={"sm"}>
                  {startDate} - {endDate}
                </Text>
              </HStack>
            </VStack>
          </VStack>
        </VStack>
      </Box>
      <Heading as={"h3"} size={"xs"} wordBreak={"break-all"} marginTop={2} width={280} style={{ opacity }}>
        {filename}
      </Heading>
    </Flex>
  );
}
