import { Avatar, Box, Button, Flex, Heading, ScaleFade, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { faBinary, faBuildingCircleArrowRight, faBuildings } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { green } from "colors";
import { BrandDivider } from "components/Miscellaneous/Brand";
import { VerticalIconCard } from "components/Miscellaneous/Cards";
import ConfigurationModal from "components/Overview/Modal/ConfigurationModal";

export default function PickOrganization({ user, organizations, setOrganization }) {
  const configurationDisclosure = useDisclosure();

  return (
    <Flex minH={"100vh"} width={"100%"} alignItems={"center"} justifyContent={"center"}>
      <ConfigurationModal {...configurationDisclosure} />
      <ScaleFade initialScale={0.9} in={true}>
        <VerticalIconCard title={"Organizations"} icon={faBuildings}>
          <Text fontSize={"lg"}>Pick an organization to sign into</Text>
          <VStack spacing={4} width={"100%"} paddingTop={2}>
            {organizations.map((org) => (
              <OrganizationButton key={org.id} organization={org} setOrganization={setOrganization} {...org.data()} />
            ))}
            {user && user.emailVerified && user.email.includes("@athlas.io") && (
              <>
                <BrandDivider marginY={4} />
                <KameleontButton onClick={configurationDisclosure.onOpen} />
              </>
            )}
          </VStack>
        </VerticalIconCard>
      </ScaleFade>
    </Flex>
  );
}

function OrganizationButton({ organization, setOrganization, Name, Logo, Color }) {
  return (
    <Button borderRadius={10} justifyContent={"flex-start"} variant={"secondary"} size={"lg"} width={"100%"} paddingX={4} paddingY={8} onClick={() => setOrganization(organization)}>
      <Flex paddingEnd={"10px"}>
        <Avatar size={"md"} name={Name} backgroundColor={Color || green} textColor={"white"} src={Logo} />
      </Flex>
      <VStack spacing={1} alignItems={"start"}>
        <Heading as={"h3"} fontSize={"lg"} textAlign={"start"}>
          {Name}
        </Heading>
        <Text textAlign={"start"} fontSize={"sm"}>
          Organization
        </Text>
      </VStack>
      <Box position="absolute" right={4}>
        <FontAwesomeIcon icon={faBuildingCircleArrowRight} aria-hidden="true" />
      </Box>
    </Button>
  );
}

function KameleontButton({ onClick }) {
  return (
    <Button borderRadius={10} justifyContent={"flex-start"} variant={"secondary"} size={"lg"} width={"100%"} paddingX={4} paddingY={8} onClick={onClick}>
      <Flex paddingEnd={"10px"}>
        <Avatar size={"md"} backgroundColor={green} textColor={"white"} icon={<FontAwesomeIcon icon={faBinary} aria-hidden="true" />} />
      </Flex>
      <VStack spacing={1} alignItems={"start"}>
        <Heading as={"h3"} fontSize={"lg"} textAlign={"start"}>
          Configuration
        </Heading>
        <Text textAlign={"start"} fontSize={"sm"}>
          Manage API keys
        </Text>
      </VStack>
    </Button>
  );
}
