import { NUMBERED_WEEKDAYS } from "data/enum";
import dayjs from "dayjs";

export const calculateNextJobInSeconds = (Schedule) => {
  let now = dayjs();

  let nextTime = getNextJob(Schedule, now);

  if (!nextTime) return null;

  let diffInSeconds = nextTime.diff(now, "second");
  return diffInSeconds;
};

export const getNextJobFormatted = (Schedule) => {
  let now = dayjs();

  let nextTime = getNextJob(Schedule, now);

  return { time: nextTime ? nextTime.format("HH:mm") : "--:--", day: nextTime ? (nextTime.isToday() ? "Today" : nextTime.isTomorrow() ? "Tomorrow" : nextTime.format("dddd")) : "Never" };
};

export const getNextJob = (Schedule, now) => {
  if (!Schedule || Object.keys(Schedule).length === 0) return null;

  let futureDates = [];

  for (const [day, hours] of Object.entries(Schedule)) {
    for (const hour of hours) {
      let nextDay = now
        .isoWeekday(NUMBERED_WEEKDAYS[day])
        .hour(Number(hour.split(":")[0]))
        .minute(Number(hour.split(":")[1]))
        .second(0);
      if (nextDay.isBefore(now)) {
        // If the time has passed for this week, go to next week
        nextDay = nextDay.add(1, "week");
      }
      futureDates.push(nextDay);
    }
  }

  futureDates.sort((a, b) => a - b); // sort the dates
  return futureDates[0]; // the soonest future date
};

export const getJobsForDay = (Schedule, day) => {
  if (Schedule && Schedule[day]) {
    return Schedule[day].length;
  }
  return 0;
};
