export function flattenArray(array) {
  const flattened = [];

  array.forEach((item) => {
    if (Array.isArray(item)) {
      flattened.push(...flattenArray(item));
    } else {
      flattened.push(item);
    }
  });

  return flattened;
}
