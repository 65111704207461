export function groupByCounty(data) {
  const groupedData = {};

  data.forEach((item) => {
    const county = item.county;

    if (!groupedData[county]) {
      groupedData[county] = [];
    }

    groupedData[county].push(item);
  });

  return groupedData;
}

export function rateCountys(data) {
  // Calculate the total calls for each county
  const totalCallsByCounty = {};
  let highestCalls = 0;

  // Iterate over the counties and their associated data
  for (const county in data) {
    if (data.hasOwnProperty(county)) {
      const countyData = data[county].data;
      let countyCalls = 0;

      // Calculate total calls for the county
      for (const item of countyData) {
        countyCalls += item.calls;
      }

      totalCallsByCounty[county] = countyCalls;

      if (countyCalls > highestCalls) {
        highestCalls = countyCalls;
      }
    }
  }

  // Calculate relative values for each county
  const relativeValues = {};

  for (const county in data) {
    if (data.hasOwnProperty(county)) {
      relativeValues[county] = Math.max(0.1, totalCallsByCounty[county] / highestCalls);
    }
  }

  return relativeValues;
}

export function calculateHitrate(calls, responses) {
  return (calls > 0 ? (responses / calls) * 100 : 0).toFixed(2);
}

export function calculateTotal(data) {
  // Initialize variables to store the total calls and responses
  let calls = 0;
  let responses = 0;

  // Iterate through the array to accumulate total calls and responses
  data.forEach((item) => {
    calls += item?.summary?.calls ?? item.calls;
    responses += item?.summary?.responses ?? item.responses;
  });

  // Calculate the hitrate based on the accumulated values
  const hitrate = calculateHitrate(calls, responses);

  return { calls, responses, hitrate };
}

export function generateRandomNumberString(length) {
  let randomNumberString = "";
  for (let i = 0; i < length; i++) {
    const randomNumber = Math.floor(Math.random() * 10); // Generates a random number between 0 and 9
    randomNumberString += randomNumber.toString(); // Convert the random number to a string and append it to the result
  }
  return randomNumberString;
}

export function getElo({ calls, responses }) {
  let elo = 1;
  let multiplier = 100 * (responses / calls);
  elo = elo + responses * 0.2;
  elo = elo + calls * 0.05;
  return elo * multiplier;
}
