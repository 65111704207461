import { Box, Flex } from "@chakra-ui/react";
import Menu, { MenuFrame } from "components/Miscellaneous/Menu";
import { MENU_ITEM } from "data/enum";
import { useEffect, useRef, useState } from "react";

export default function Overview({ config, numberGroups, groupUsers, currentUser, organizations, showOrganizationPicker }) {
  const [selectedMenuItem, setSelectedMenuItem] = useState(MENU_ITEM.DASHBOARD);
  const [selectedNumberGroup, setSelectedNumberGroup] = useState(null);
  const [extraColumn, setExtraColumn] = useState(null);
  const [box3Width, setBox3Width] = useState(0);
  const [box3ContentWdith, setBox3ContentWidth] = useState(0);
  const flexRef = useRef(null);
  const box3Ref = useRef(null);

  useEffect(() => {
    setExtraColumn(null);
  }, [selectedMenuItem]);

  /* Calculate filler element width */
  useEffect(() => {
    const updateBox3Width = () => {
      if (flexRef.current && box3Ref.current) {
        const flexWidth = flexRef.current.offsetWidth;
        const menuWidth = 300;
        const containerWidth = 1200;

        const availableSpace = flexWidth - menuWidth - containerWidth;

        setBox3ContentWidth((flexWidth - containerWidth) / 2);

        if (availableSpace >= 300) {
          setBox3Width(300);
        } else {
          setBox3Width(0);
        }
      }
    };

    window.addEventListener("resize", updateBox3Width);

    updateBox3Width();

    return () => {
      window.removeEventListener("resize", updateBox3Width);
    };
  }, []);

  return (
    <Flex direction="row" height="100vh" width="100vw" justifyContent={"space-between"} ref={flexRef}>
      <Box width={300} height={"100%"} overflow={"hidden"}>
        <Menu
          selectedMenuItem={selectedMenuItem}
          setSelectedMenuItem={setSelectedMenuItem}
          selectedNumberGroup={selectedNumberGroup}
          setSelectedNumberGroup={setSelectedNumberGroup}
          numberGroups={numberGroups}
          groupUsers={groupUsers}
          currentUser={currentUser}
          config={config}
          organizations={organizations}
          showOrganizationPicker={showOrganizationPicker}
        />
      </Box>

      <Box flex="1" maxWidth={1200} width={"auto"} minWidth={0} overflowX="hidden" overflowY={"auto"}>
        <MenuFrame config={config} selectedMenuItem={selectedMenuItem} selectedNumberGroup={selectedNumberGroup} numberGroups={numberGroups} currentUser={currentUser} groupUsers={groupUsers} setExtraColumn={setExtraColumn} />
      </Box>
      <Box width={box3Width} maxWidth={300} minWidth={0} overflow="hidden" ref={box3Ref}>
        {/* Filler element to set container to middle of screen if possible */}
      </Box>
      {extraColumn && box3Width > 0 && (
        <Flex justifyContent={"center"} alignItems={"center"} position={"absolute"} top={0} right={0} bottom={0} width={box3ContentWdith}>
          {extraColumn}
        </Flex>
      )}
    </Flex>
  );
}
