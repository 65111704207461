// Import the functions you need from the SDKs you need
import { COLLECTION } from "data/enum";
import { getApps, initializeApp } from "firebase/app";
import { ReCaptchaV3Provider, initializeAppCheck } from "firebase/app-check";
import { addDoc, collection, getFirestore, serverTimestamp } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { IS_DEVELOPMENT } from "variables";

export const initializeFirebase = () => {
  const firebaseConfig = IS_DEVELOPMENT
    ? {
        apiKey: "AIzaSyBGcaRvLdxfV74KABILPCePOkym1d_TYmI",
        authDomain: "kameleont-athlasio-development.firebaseapp.com",
        databaseURL: "https://kameleont-athlasio-development-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "kameleont-athlasio-development",
        storageBucket: "kameleont-athlasio-development.appspot.com",
        messagingSenderId: "318302824691",
        appId: "1:318302824691:web:82e3efe80a8f8d7600af86",
      }
    : {
        apiKey: "AIzaSyAv-0sR_Ry88FnXeea0DUohJMpPWjyQdeA",
        authDomain: "kameleont-athlasio.firebaseapp.com",
        databaseURL: "https://kameleont-athlasio-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "kameleont-athlasio",
        storageBucket: "kameleont-athlasio.appspot.com",
        messagingSenderId: "321983138847",
        appId: "1:321983138847:web:87211849eda18e6f15192a",
      };

  if (getApps().length < 1) {
    const app = initializeApp(firebaseConfig);
    getStorage(app);
    getFirestore(app);

    // If debug enviorment set token to allow firebase services
    if (IS_DEVELOPMENT) {
      window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_DEBUG_TOKEN;
    }

    initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(IS_DEVELOPMENT ? "6LeUT4YnAAAAAE6fsg5BBVlfPDPVZKqqKlLXM85y" : "6LctS4YnAAAAAFof0DEntJ6HZNfHQdfelnKSPW_a"),
      isTokenAutoRefreshEnabled: true,
    });
  }
};

export function LogActivityGroup(organizationId, groupId, action, type, param, user) {
  const db = getFirestore();
  const ref = collection(db, COLLECTION.ORGANIZATIONS, organizationId, COLLECTION.GROUPS, groupId, COLLECTION.ACTIVITY);
  LogActivity(ref, action, type, param, groupId, user);
}

export function LogActivityOrganization(organizationId, action, type, param, user) {
  const db = getFirestore();
  const ref = collection(db, COLLECTION.ORGANIZATIONS, organizationId, COLLECTION.ACTIVITY);
  LogActivity(ref, action, type, param, organizationId, user);
}

function LogActivity(ref, action, type, param, parent, user) {
  if (!user) {
    console.error("Could not log, user not found", user);
    return null;
  }

  addDoc(ref, {
    Action: action,
    Type: type,
    Parameter: param,
    User: user.Email,
    Timestamp: serverTimestamp(),
    Parent: parent,
    BySupport: user.isSupport,
  });
}
