import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { grey, lightgrey } from "colors";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { IS_PRODUCTION } from "variables";
import "./index.css";
import Error from "./pages/Error";
import Router from "./pages/Router";

const root = ReactDOM.createRoot(document.getElementById("root"));

const components = {
  Button: {
    variants: {
      solid: (props) => ({
        bg: props.colorScheme === "brand" ? "brand.500" : "",
        color: "white",
        _hover: {
          bg: props.colorScheme === "brand" ? "brand.600" : "",
          _disabled: {
            bg: props.colorScheme === "brand" ? "brand.500" : "",
          },
        },
        _active: { bg: props.colorScheme === "brand" ? "brand.600" : "" },
      }),
      secondary: () => ({
        bg: "secondary.500",
        color: "secondary.text",
        _hover: {
          bg: "secondary.600",
          _disabled: {
            bg: "secondary.500",
            cursor: "not-allowed",
          },
        },
        _active: {
          bg: "secondary.600",
        },
      }),
      ghost: () => ({
        bg: "transparent",
        color: "secondary.text",
        _hover: {
          bg: "secondary.300",
          _disabled: {
            bg: "secondary.200",
            cursor: "not-allowed",
          },
        },
        _active: {
          bg: "secondary.500",
        },
      }),
      danger: () => ({
        bg: "danger.500",
        color: "white",
        _hover: {
          bg: "danger.600",
          _disabled: {
            bg: "danger.500",
            cursor: "not-allowed",
          },
        },
        _active: {
          bg: "danger.600",
        },
      }),
    },
  },
  Select: {
    variants: {
      secondary: () => ({
        field: {
          bg: "secondary.500",
          color: "secondary.text",
          _hover: {
            bg: "secondary.600",
            _disabled: {
              bg: "secondary.500",
              cursor: "not-allowed",
            },
          },
          _active: {
            bg: "secondary.600",
          },
        },
      }),
    },
  },
  Checkbox: {
    variants: {
      danger: {
        control: {
          bg: lightgrey,
          borderColor: grey,
          _checked: {
            bg: "danger.500",
            borderColor: "danger.500",
            color: "white",
            _hover: {
              bg: "danger.500",
              borderColor: "danger.500",
            },
          },
          _indeterminate: {
            bg: "danger.500",
            borderColor: "danger.500",
          },
        },
      },
    },
  },
  Input: {
    defaultProps: {
      focusBorderColor: "brand.500",
    },
    variants: {
      outline: {
        field: {
          borderColor: "secondary.600",
          _hover: {
            borderColor: "secondary.700",
          },
        },
      },
    },
  },
  Spinner: {
    baseStyle: {
      color: "brand.500", // set default color
    },
  },
};

const styles = {
  global: (props) => ({
    body: {
      bg: "#f7f7f7",
    },
  }),
};

const colors = {
  brand: {
    900: "#0e4424",
    800: "#105c2a",
    700: "#127530",
    600: "#148e36",
    500: "#17a048",
    400: "#3db662",
    300: "#63cc7c",
    200: "#89e296",
    100: "#aef0af",
  },
  secondary: {
    900: "#37302A",
    800: "#625757",
    700: "#8D8484",
    600: "#d4cbcb",
    500: "#E3DEDE",
    400: "#DBD5D5",
    300: "#E5E5E5",
    200: "#EDEDED",
    100: "#F5F5F5",
    text: "#1A202C",
  },
  tabs: {
    900: "#bfbaba",
    800: "#bfbaba",
    700: "#bfbaba",
    600: "#bfbaba",
    500: "#bfbaba",
    400: "#bfbaba",
    300: "#bfbaba",
    200: "#bfbaba",
    100: "#bfbaba",
    text: "#090a19",
  },
  danger: {
    900: "#840000",
    800: "#a00000",
    700: "#bd0000",
    600: "#CC0000",
    500: "#e03333",
    400: "#f36767",
    300: "#f59999",
    200: "#f8cccc",
    100: "#fce8e8",
  },
};

const theme = extendTheme({
  components,
  styles,
  colors,
});

// Disable logs in production
if (IS_PRODUCTION) {
  console.log = () => {};
}

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Router />} />
          <Route path="/login" element={<Router />} />
          <Route path="/reset" element={<Router />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);
