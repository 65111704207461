import { Avatar, Box, Button, Collapse, Flex, HStack, Heading, Text, VStack, useBoolean, useDisclosure } from "@chakra-ui/react";
import { faAdd, faBlender, faCaretDown, faComputerClassic, faDisplayChartUp, faEyeSlash, faFileInvoice, faFolder, faFolderOpen, faHourglassClock, faList12, faPause, faPlay, faSwap, faUserCog } from "@fortawesome/pro-duotone-svg-icons";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { black, danger, green, lightgrey, lightlightgrey, lightlightlightgrey, white, yellow } from "colors";
import KameleontSVG from "components/Logo/Kameleont";
import Analyzer from "components/Overview/Analyzer";
import { Dashboard } from "components/Overview/Dashboard";
import Invoices from "components/Overview/Invoices";
import AddGroupModal from "components/Overview/Modal/AddGroupModal";
import NumberGroup from "components/Overview/NumberGroup";
import Scrambler from "components/Overview/Scrambler";
import Settings from "components/Overview/Settings";
import { MENU_ITEM } from "data/enum";
import NoGroups from "pages/Miscellaneous/NoGroups";
import Unavailable from "pages/Miscellaneous/Unavailable";
import { useEffect, useState } from "react";
import { IS_DEVELOPMENT } from "variables";
import { BrandDivider } from "./Brand";

export default function Menu({ selectedMenuItem, setSelectedMenuItem, selectedNumberGroup, setSelectedNumberGroup, numberGroups, groupUsers, currentUser, config, organizations, showOrganizationPicker }) {
  const [showNumberGroups, setShowNumberGroups] = useBoolean(false);
  const [expandedFolder, setExpandedFolder] = useState(null);

  const addGroupDisclosure = useDisclosure();

  function onMenuItemClick(menuItem) {
    if (menuItem === MENU_ITEM.NUMBER_GROUPS) {
      setShowNumberGroups.toggle();
    } else {
      setSelectedMenuItem(menuItem);
      setSelectedNumberGroup(null);
    }
  }

  function onNumberGroupClick(numberGroupId) {
    setSelectedMenuItem(MENU_ITEM.NUMBER_GROUPS);
    setSelectedNumberGroup(numberGroupId);
  }

  useEffect(() => {
    const currentGroupIndex = numberGroups.findIndex((group) => group.id === selectedNumberGroup);

    if (currentGroupIndex)
      if (currentGroupIndex !== -1 && !numberGroups[currentGroupIndex].Enabled) {
        // Look for the first previous enabled group
        for (let i = currentGroupIndex - 1; i >= 0; i--) {
          if (numberGroups[i].Enabled) {
            setSelectedNumberGroup(numberGroups[i].id);
            return;
          }
        }

        // If no previous enabled group found, look for the first next enabled group
        for (let i = currentGroupIndex + 1; i < numberGroups.length; i++) {
          if (numberGroups[i].Enabled) {
            setSelectedNumberGroup(numberGroups[i].id);
            return;
          }
        }

        // If no enabled group is found at all, set to null
        setSelectedNumberGroup(null);
      }
    // eslint-disable-next-line
  }, [selectedNumberGroup, numberGroups]);

  const hiddenGroups = currentUser?.HiddenGroups ?? [];
  const expandedScreening = currentUser?.Settings?.ExpandedScreening ?? false;

  const _filteredNumberGroups = numberGroups
    .filter((group) => group.Enabled)
    .sort((a, b) => {
      const keysA = a?.Schedule ? Object.keys(a.Schedule).length : 0;
      const keysB = b?.Schedule ? Object.keys(b.Schedule).length : 0;

      if (keysA > 0 && keysB === 0) {
        return -1; // A has keys and B doesn't, A should come first
      } else if (keysB > 0 && keysA === 0) {
        return 1; // B has keys and A doesn't, B should come first
      } else {
        return 0; // Both have keys or both don't, maintain original order
      }
    })
    .sort((a, b) => ((currentUser?.FavoriteGroups ?? []).includes(b.id) ? 1 : 0) - ((currentUser?.FavoriteGroups ?? []).includes(a.id) ? 1 : 0));

  const filteredNumberGroups = _filteredNumberGroups.filter((item) => !hiddenGroups.includes(item.id));
  const hiddenNumberGroups = _filteredNumberGroups.filter((item) => hiddenGroups.includes(item.id));

  let folderedItems = {};

  filteredNumberGroups.forEach((item) => {
    if (item?.Folder) {
      folderedItems[item.Folder] = folderedItems[item.Folder] || [];
      folderedItems[item.Folder].push(item);
    }
  });

  const folderedElements = Object.entries(folderedItems).map(([name, items]) => (
    <NumberGroupFolder key={name} name={name} isExpanded={expandedFolder === name} setExpanded={setExpandedFolder} isSelected={items.some((item) => item.id === selectedNumberGroup)}>
      {items.map((group) => (
        <NumberGroupItem key={group.id} selectedNumberGroup={selectedNumberGroup} onNumberGroupClick={onNumberGroupClick} isFavorite={(currentUser?.FavoriteGroups ?? []).includes(group.id)} isInFolder={true} {...group} />
      ))}
    </NumberGroupFolder>
  ));

  const nonFolderedElements = filteredNumberGroups
    .filter((item) => (item?.Folder ?? "").length === 0)
    .map((group) => <NumberGroupItem key={group.id} selectedNumberGroup={selectedNumberGroup} onNumberGroupClick={onNumberGroupClick} isFavorite={(currentUser?.FavoriteGroups ?? []).includes(group.id)} {...group} />);

  return (
    <>
      <Box position={"fixed"} top={0} right={0} zIndex={999} pointerEvents={"none"}>
        <VStack width={300} height={300} justifyContent={"center"} alignItems={"center"} marginTop={"-100"} marginRight={"-100"} transform={"rotate(45deg)"}>
          {config.Demo && (
            <Box width={300} backgroundColor={danger} borderRadius={10} paddingX={2} paddingY={1} marginX={2}>
              <Text width={"100%"} textAlign={"center"} fontWeight={"bold"} fontSize={"xs"} color={white}>
                DEMO
              </Text>
            </Box>
          )}
          {IS_DEVELOPMENT && (
            <Box width={300} backgroundColor={danger} borderRadius={10} paddingX={2} paddingY={1} marginX={2}>
              <Text fontWeight={"bold"} width={"100%"} textAlign={"center"} fontSize={"xs"} color={white}>
                DEVELOPMENT
              </Text>
            </Box>
          )}
        </VStack>
      </Box>
      <AddGroupModal {...addGroupDisclosure} config={config} currentUser={currentUser} />
      <Flex direction="column" h="100%" borderStyle={"solid"} borderRightWidth={2} borderColor={lightlightgrey} backgroundColor={lightlightlightgrey} padding={4}>
        <Box marginLeft={4}>
          <KameleontSVG width={60} />
        </Box>
        <VStack flex={1} spacing={2} overflow={"auto"} width={"100%"} paddingTop={4} position={"relative"}>
          <MenuItem text={"Dashboard"} icon={faDisplayChartUp} item={MENU_ITEM.DASHBOARD} selectedMenuItem={selectedMenuItem} onMenuItemClick={onMenuItemClick} />
          <Box width={"100%"}>
            <Box width={"100%"} style={expandedScreening && showNumberGroups ? { position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 999, backgroundColor: lightlightlightgrey } : null}>
              <MenuItem text={"Number Screening"} icon={faList12} item={MENU_ITEM.NUMBER_GROUPS} selectedMenuItem={selectedMenuItem} onMenuItemClick={onMenuItemClick} showNumberGroups={showNumberGroups} />
              <Collapse in={showNumberGroups} animateOpacity style={{ width: "100%" }}>
                <Box width={"100%"} height={"100%"} backgroundColor={lightlightgrey} borderWidth={1} borderStyle={"solid"} borderColor={lightgrey} borderRadius={10} overflow={"hidden"}>
                  <VStack spacing={0} gap={0}>
                    {currentUser?.Settings?.FoldersFirst ?? true ? (
                      <>
                        {folderedElements}
                        {nonFolderedElements}
                      </>
                    ) : (
                      <>
                        {nonFolderedElements}
                        {folderedElements}
                      </>
                    )}
                    {hiddenNumberGroups.length > 0 && (
                      <NumberGroupFolder name={"Hidden"} isExpanded={expandedFolder === "Hidden"} setExpanded={setExpandedFolder} isSelected={hiddenNumberGroups.some((item) => item.id === selectedNumberGroup)} hidden={true}>
                        {hiddenNumberGroups.map((group) => (
                          <NumberGroupItem key={group.id} selectedNumberGroup={selectedNumberGroup} onNumberGroupClick={onNumberGroupClick} isFavorite={(currentUser?.FavoriteGroups ?? []).includes(group.id)} isInFolder={true} {...group} />
                        ))}
                      </NumberGroupFolder>
                    )}
                  </VStack>
                  <BrandDivider marginY={0} />
                  <Box paddingTop={0}>
                    <Button variant={"ghost"} minHeight={10} size={"sm"} width={"100%"} borderRadius={6} opacity={0.5} onClick={addGroupDisclosure.onOpen}>
                      <HStack flex={1} spacing={0} alignItems={"center"}>
                        <Flex width={8}>
                          <FontAwesomeIcon icon={faAdd} />
                        </Flex>
                        <Text>Create group</Text>
                      </HStack>
                    </Button>
                  </Box>
                </Box>
              </Collapse>
            </Box>
          </Box>

          <MenuItem text={"Number Scrambler"} icon={faBlender} item={MENU_ITEM.NUMBER_SCRAMBLER} selectedMenuItem={selectedMenuItem} onMenuItemClick={onMenuItemClick} />
          <MenuItem text={"AI Analyser"} icon={faComputerClassic} item={MENU_ITEM.MACHINE_LEARNING} selectedMenuItem={selectedMenuItem} onMenuItemClick={onMenuItemClick} />
          {currentUser.isSupport && <MenuItem text={"Invoices"} icon={faFileInvoice} item={MENU_ITEM.INVOICES} selectedMenuItem={selectedMenuItem} onMenuItemClick={onMenuItemClick} />}
          <MenuItem text={"Settings"} icon={faUserCog} item={MENU_ITEM.SETTINGS} selectedMenuItem={selectedMenuItem} onMenuItemClick={onMenuItemClick} />
        </VStack>
        <VStack alignItems={"flex-start"} width={"100%"}>
          <Box width={"100%"}>
            <BrandDivider marginY={2} />
          </Box>
          <Box paddingBottom={4} width={"100%"}>
            <Button variant={"unstyled"} width={"100%"} disabled={organizations.length === 1} cursor={organizations.length === 1 ? "default" : "pointer"} onClick={organizations.length > 1 ? () => showOrganizationPicker(true) : null}>
              <HStack alignItems={"center"} paddingX={2} gap={2}>
                <Avatar size={"md"} name={config.Name} backgroundColor={config.Color || green} textColor={"white"} src={config.Logo} />
                <VStack spacing={0} alignItems={"start"}>
                  <Heading size={"md"} color={config.Color}>
                    {config.Name}
                  </Heading>
                  <Text color={black}>Organization</Text>
                </VStack>
                {organizations.length > 1 && (
                  <Box position="absolute" right={2}>
                    <FontAwesomeIcon icon={faSwap} aria-hidden="true" />
                  </Box>
                )}
              </HStack>
            </Button>
          </Box>
        </VStack>
      </Flex>
    </>
  );
}

function NumberGroupFolder({ name, isExpanded, isSelected, setExpanded, hidden, children }) {
  return (
    <>
      <Button height={10} variant={"ghost"} size={"md"} width={"100%"} opacity={isSelected ? 1 : hidden ? 0.45 : 0.65} onClick={() => setExpanded(isExpanded ? null : name)} borderRadius={0}>
        <HStack spacing={0} gap={2} alignItems={"center"} justifyContent={"flex-start"} margin={0} padding={0} width={"100%"}>
          <Box width={4}>
            <FontAwesomeIcon size={"sm"} icon={hidden ? faEyeSlash : isExpanded ? faFolderOpen : faFolder} />
          </Box>
          <Box overflow={"hidden"} width={"100%"} textAlign={"left"}>
            <Text fontSize={14} overflow={"hidden"} textOverflow={"ellipsis"}>
              {name}
            </Text>
          </Box>
        </HStack>
        <Box transform={`rotate(${isExpanded ? "180deg" : "0deg"})`} transition="transform 0.2s ease-in-out">
          <FontAwesomeIcon icon={faCaretDown} />
        </Box>
      </Button>
      <Collapse in={isExpanded} animateOpacity style={{ width: "100%" }}>
        <Box width={"100%"} height={"100%"} borderRadius={10} borderTopRadius={0} overflow={"hidden"} marginBottom={2}>
          {children}
        </Box>
      </Collapse>
    </>
  );
}

function NumberGroupItem({ id, Title, Schedule, selectedNumberGroup, onNumberGroupClick, isFavorite, isInFolder }) {
  return (
    <Button height={10} variant={"ghost"} size={"md"} width={"100%"} opacity={selectedNumberGroup === id ? 1 : 0.65} onClick={() => onNumberGroupClick(id)} backgroundColor={selectedNumberGroup === id ? lightgrey : null} borderRadius={0} paddingStart={isInFolder ? 8 : 4}>
      <HStack spacing={0} gap={2} alignItems={"center"} justifyContent={"flex-start"} margin={0} padding={0} width={"100%"}>
        <Box width={4}>
          <FontAwesomeIcon size={"sm"} icon={Schedule ? (Object.keys(Schedule).length > 0 ? faPlay : faPause) : faHourglassClock} color={Schedule ? (Object.keys(Schedule).length > 0 ? green : danger) : null} />
        </Box>
        <Box overflow={"hidden"} width={"100%"} textAlign={"left"}>
          <Text fontSize={14} overflow={"hidden"} textOverflow={"ellipsis"}>
            {Title}
          </Text>
        </Box>
        {isFavorite && <FontAwesomeIcon icon={faStar} color={yellow} />}
      </HStack>
    </Button>
  );
}

function MenuItem({ text, icon, item, selectedMenuItem, onMenuItemClick, showNumberGroups }) {
  return (
    <Button variant={"ghost"} minHeight={10} size={"md"} width={"100%"} opacity={selectedMenuItem === item ? 1 : 0.65} onClick={() => onMenuItemClick(item)} backgroundColor={selectedMenuItem === item && item !== MENU_ITEM.NUMBER_GROUPS ? lightgrey : null} borderRadius={6}>
      <HStack flex={1} spacing={0} alignItems={"center"}>
        <Flex width={8}>
          <FontAwesomeIcon icon={icon} />
        </Flex>
        <Text>{text}</Text>
      </HStack>
      {item === MENU_ITEM.NUMBER_GROUPS && (
        <Box transform={`rotate(${showNumberGroups ? "180deg" : "0deg"})`} transition="transform 0.2s ease-in-out">
          <FontAwesomeIcon icon={faCaretDown} />
        </Box>
      )}
    </Button>
  );
}

export function MenuFrame({ config, selectedMenuItem, selectedNumberGroup, numberGroups, groupUsers, currentUser, setExtraColumn }) {
  let enabledNumberGroups = numberGroups.filter((group) => group.Enabled);
  if (selectedMenuItem === MENU_ITEM.DASHBOARD) {
    return <Dashboard config={config} numberGroups={enabledNumberGroups} groupUsers={groupUsers} currentUser={currentUser} />;
  } else if (selectedMenuItem === MENU_ITEM.NUMBER_GROUPS) {
    let numberGroup = enabledNumberGroups.find((x) => x.id === selectedNumberGroup);
    if (numberGroup) {
      return <NumberGroup config={config} groupUsers={groupUsers} currentUser={currentUser} {...numberGroup} />;
    } else {
      return <NoGroups />;
    }
  } else if (selectedMenuItem === MENU_ITEM.NUMBER_SCRAMBLER) {
    return <Scrambler config={config} />;
  } else if (selectedMenuItem === MENU_ITEM.INVOICES) {
    return <Invoices config={config} />;
  } else if (selectedMenuItem === MENU_ITEM.MACHINE_LEARNING) {
    return <Analyzer config={config} currentUser={currentUser} setExtraColumn={setExtraColumn} />;
  } else if (selectedMenuItem === MENU_ITEM.SETTINGS) {
    return <Settings config={config} currentUser={currentUser} groupUsers={groupUsers} />;
  } else {
    return <Unavailable />;
  }
}
