import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Select,
  Skeleton,
  SkeletonText,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { faBrowser, faCodeBranch, faEyeSlash, faGear, faHashtag, faNewspaper, faSack, faTruckRampBox, faZzz } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatActivity } from "api/activity";
import { calculateEniroCost, calculateHittaCost, roundDown } from "api/cost";
import { accentgrey, lightgrey, white } from "colors";
import { CardDivider } from "components/Miscellaneous/Brand";
import { Card, HorizontalIconCard } from "components/Miscellaneous/Cards";
import dayjs from "dayjs";
import { getApp } from "firebase/app";
import { getDatabase, onValue, ref } from "firebase/database";
import { Timestamp } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import PatchNotesModal from "./Modal/PatchNotesModal";

export function Dashboard({ config, numberGroups, groupUsers, currentUser }) {
  const [selectedTimespan, setSelectedTimespan] = useState("thisMonth");
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState(null);

  const patchNotesDisclosure = useDisclosure();

  const handleTimespanChange = (event) => {
    setSelectedTimespan(event.target.value);
  };

  const getStatistics = async () => {
    const functions = getFunctions(getApp(), "europe-west1");
    const getStatistics = httpsCallable(functions, "GetStatistics");
    const result = await getStatistics({
      organizationId: config.ID,
      support: currentUser.isSupport,
      monthOffset: timespanOptions[selectedTimespan].monthOffset,
    });
    setData(result.data);
  };

  const currentDate = dayjs();
  const timespanOptions = {
    thisMonth: {
      text: `${currentDate.startOf("month").format("DD MMMM")} - ${currentDate.format("DD MMMM")}`,
      monthOffset: 0,
    },
    lastMonth: {
      text: currentDate.add(-1, "month").format("MMMM YYYY"),
      monthOffset: 1,
    },
    previousMonth: {
      text: currentDate.add(-2, "month").format("MMMM YYYY"),
      monthOffset: 2,
    },
  };

  useEffect(() => {
    setIsLoading(true);
    getStatistics().finally(() => {
      setIsLoading(false);
    });
    // eslint-disable-next-line
  }, [selectedTimespan]);

  useEffect(() => {
    const database = getDatabase();
    const statusRef = ref(database, config.ID + "/status");
    onValue(statusRef, (snapshot) => {
      setStatus(snapshot.val());
    });
    // eslint-disable-next-line
  }, []);

  const eniroCost = calculateEniroCost(data?.history?.Eniro ?? 0);
  const hittaCost = calculateHittaCost(data?.history?.Hitta ?? 0);
  const totalCost = (eniroCost + hittaCost) * 1.1;

  const isAnyGroupRunning = status?.Processing ?? data?.status?.Processing;

  return (
    <>
      <>
        <PatchNotesModal disclosure={patchNotesDisclosure} currentUser={currentUser} />
      </>
      <VStack padding={8} width={"100%"} gap={4} alignItems={"flex-start"}>
        <VStack width={"100%"} alignItems={"flex-start"} justifyContent={"flex-start"}>
          <HStack justifyContent={"space-between"} alignItems={"flex-start"} width={"100%"}>
            <VStack alignItems={"flex-start"} justifyContent={"flex-start"}>
              <Heading size={"lg"}>Dashboard</Heading>
              <Text>Overview of your organization and groups</Text>
            </VStack>
            <HStack>
              <Tooltip label={"Change time period"}>
                <Select maxWidth={250} onChange={handleTimespanChange} variant={"secondary"} disabled={isLoading} fontWeight={"bold"}>
                  {Object.keys(timespanOptions).map((key) => (
                    <option key={key} value={key}>
                      {timespanOptions[key].text}
                    </option>
                  ))}
                </Select>
              </Tooltip>
            </HStack>
          </HStack>
        </VStack>
        <CardDivider />
        <Card title={"Information"} text={"General information about the service."}>
          <HStack width={"100%"} justifyContent={"space-evenly"} paddingTop={4}>
            <HorizontalIconCard title={"Version"} icon={faCodeBranch}>
              <HStack alignItems={"flex-end"} minWidth={50} width={100}>
                <Text fontSize={"2xl"} as={"b"}>
                  {process.env.REACT_APP_VERSION}
                </Text>
                <Text fontSize={"mg"} paddingBottom={1} color={accentgrey}>
                  v
                </Text>
              </HStack>
            </HorizontalIconCard>

            <HorizontalIconCard title={"Patch notes"} icon={faNewspaper}>
              <Skeleton isLoaded={!isLoading}>
                <HStack alignItems={"flex-end"} minWidth={50} width={100}>
                  <Button variant={"secondary"} onClick={patchNotesDisclosure.onOpen} isDisabled={isLoading} height={30} marginTop={1}>
                    View
                  </Button>
                </HStack>
              </Skeleton>
            </HorizontalIconCard>

            <HorizontalIconCard title={"Status"} icon={isAnyGroupRunning ? faGear : faZzz} spinning={isAnyGroupRunning}>
              <Skeleton isLoaded={!isLoading}>
                <HStack alignItems={"flex-end"} minWidth={50} width={100}>
                  <Text fontSize={"2xl"} as={"b"}>
                    {isAnyGroupRunning ? "Processing" : "Idle"}
                  </Text>
                  <Text fontSize={"mg"} paddingBottom={1} color={accentgrey}></Text>
                </HStack>
              </Skeleton>
            </HorizontalIconCard>
          </HStack>
        </Card>
        <CardDivider />
        <Card title={"Statistics"} text={"Overview of performance and current groups."}>
          <HStack width={"100%"} justifyContent={"space-evenly"} paddingTop={4}>
            <HorizontalIconCard title={"Numbers"} icon={faHashtag}>
              <Skeleton isLoaded={!isLoading}>
                <HStack alignItems={"flex-end"} minWidth={50} width={100}>
                  <Text fontSize={"2xl"} as={"b"}>
                    {data?.numbers ?? 0}
                  </Text>
                  <Text fontSize={"mg"} paddingBottom={1} color={accentgrey}></Text>
                </HStack>
              </Skeleton>
            </HorizontalIconCard>

            <HorizontalIconCard title={"Jobs"} icon={faTruckRampBox}>
              <Skeleton isLoaded={!isLoading}>
                <HStack alignItems={"flex-end"} minWidth={50} width={100}>
                  <Text fontSize={"2xl"} as={"b"}>
                    {data?.statistics?.Jobs ?? 0}
                  </Text>
                  <Text fontSize={"mg"} paddingBottom={1} color={accentgrey}></Text>
                </HStack>
              </Skeleton>
            </HorizontalIconCard>

            <HorizontalIconCard title={"Requests"} icon={faBrowser}>
              <Skeleton isLoaded={!isLoading}>
                <HStack alignItems={"flex-end"} minWidth={50} width={100}>
                  <Text fontSize={"2xl"} as={"b"}>
                    {data?.statistics?.Requests ?? 0}
                  </Text>
                  <Text fontSize={"mg"} paddingBottom={1} color={accentgrey}></Text>
                </HStack>
              </Skeleton>
            </HorizontalIconCard>

            <HorizontalIconCard title={"Estimated cost"} icon={faSack}>
              <Skeleton isLoaded={!isLoading}>
                <HStack alignItems={"flex-end"} minWidth={50} width={100}>
                  <Text fontSize={"2xl"} as={"b"}>
                    {roundDown(totalCost ?? 0)}
                  </Text>
                  <Text fontSize={"mg"} paddingBottom={1} color={accentgrey}>
                    kr
                  </Text>
                </HStack>
              </Skeleton>
            </HorizontalIconCard>
          </HStack>
        </Card>
        <CardDivider />
        <Card title={"Activity"} text={"Audit log of organization and groups."}>
          <SkeletonText noOfLines={5} skeletonHeight={10} width={"100%"} isLoaded={!isLoading}>
            <Box width={"100%"} backgroundColor={white} borderRadius={10} borderColor={lightgrey} borderWidth={2}>
              <Table variant="striped">
                <Thead>
                  <Tr>
                    <Th>User</Th>
                    <Th>Action</Th>
                    {currentUser?.isSupport && (
                      <Th>
                        Data <FontAwesomeIcon icon={faEyeSlash} />
                      </Th>
                    )}
                    <Th>Parent</Th>
                    <Th>Timestamp</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {(data?.activity ?? []).map((item) => (
                    <Tr key={JSON.stringify(item)} opacity={item?.BySupport ? 0.75 : 1}>
                      <Td>
                        <HStack>
                          <Avatar
                            name={groupUsers.find((x) => x.Email === item.User)?.Name ?? item.User}
                            size={"sm"}
                            marginEnd={2}
                            src={groupUsers.find((x) => x.Email === item.User)?.Logo}
                          />
                          <Flex direction={"column"}>
                            <Text fontSize={"sm"}>{groupUsers.find((x) => x.Email === item.User)?.Name}</Text>
                            <Text fontSize={"sm"} fontWeight={"bold"} marginTop={"0px"}>
                              {item.User}
                            </Text>
                          </Flex>
                        </HStack>
                      </Td>
                      <Td>
                        <Text fontSize={"sm"} fontWeight={"bold"}>
                          {item.Action} {item.Type}
                        </Text>
                      </Td>
                      {currentUser?.isSupport && (
                        <Td>
                          <Text fontSize={"sm"} fontWeight={"bold"}>
                            {formatActivity(item.Type, item?.Parameter)}
                          </Text>
                        </Td>
                      )}
                      <Td>
                        <Text fontSize={"sm"} fontWeight={"bold"}>
                          {item.Parent === config.ID ? config.Name : numberGroups.find((x) => x.id === item.Parent)?.Title ?? "[DELETED]"}
                        </Text>
                      </Td>
                      <Td position={"relative"}>
                        <Text fontSize={"sm"}>
                          <Text fontSize={"sm"} fontWeight={"bold"} as={"span"} paddingEnd={2}>
                            {dayjs(new Timestamp(item.Timestamp._seconds, item.Timestamp._nanoseconds).toDate()).format("DD MMMM YYYY")}
                          </Text>
                          {dayjs(new Timestamp(item.Timestamp._seconds, item.Timestamp._nanoseconds).toDate()).format("HH:mm")}
                        </Text>
                      </Td>
                      <Td>{item?.BySupport && <FontAwesomeIcon icon={faEyeSlash} />}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </SkeletonText>
        </Card>
      </VStack>
    </>
  );
}
