import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Stack, Text, useBoolean, useDisclosure } from "@chakra-ui/react";
import { faPersonToDoor, faUnlockKeyhole } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import ForgotPasswordModal from "../../components/Login/Modal/ForgotPasswordModal";
import KameleontSVG from "../../components/Logo/Kameleont";
import { BrandDivider } from "../../components/Miscellaneous/Brand";

export default function Login() {
  const [isLoading, setLoading] = useBoolean();
  const [loginFailed, setLoginFailed] = useBoolean();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const resetEmailDisclosure = useDisclosure();

  const login = () => {
    if (isLoading) return;

    setLoading.on();
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password).catch((error) => {
      console.error(error.message);
      setLoginFailed.on();
      setLoading.off();
    });
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      login();
    }
  };

  return (
    <>
      <ForgotPasswordModal {...resetEmailDisclosure} />
      <Flex minH={"100vh"} align={"center"} justify={"center"}>
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <KameleontSVG width={150} height={150} />
            <Heading fontSize={"4xl"}>Sign in to your account</Heading>
            <Text fontSize={"lg"}>Manage your phone numbers with ease</Text>
          </Stack>
          <Box rounded={"lg"} backgroundColor={"white"} boxShadow={"lg"} p={8}>
            <Stack spacing={4}>
              <FormControl id="email" onSubmit={login} isInvalid={loginFailed}>
                <FormLabel>Email address</FormLabel>
                <Input name="email" type="email" autoComplete="email" placeholder="example@email.com" isRequired value={email} isDisabled={isLoading} isInvalid={loginFailed} onChange={(e) => setEmail(e.target.value)} onKeyDownCapture={onKeyPress} autoFocus />
              </FormControl>
              <FormControl id="password" onSubmit={login} isInvalid={loginFailed}>
                <FormLabel>Password</FormLabel>
                <Input name="password" type="password" autoComplete="current-password" placeholder="********" isRequired value={password} isDisabled={isLoading} isInvalid={loginFailed} onChange={(e) => setPassword(e.target.value)} onKeyDownCapture={onKeyPress} />
                <FormErrorMessage>Invalid email or password. Please try again.</FormErrorMessage>
              </FormControl>
              <BrandDivider />
              <Stack spacing={4}>
                <Button colorScheme="brand" onClick={login} isDisabled={isLoading} isLoading={isLoading}>
                  Sign in
                  <Box position="absolute" right={4}>
                    <FontAwesomeIcon icon={faPersonToDoor} aria-hidden="true" />
                  </Box>
                </Button>
                <Button onClick={resetEmailDisclosure.onOpen} isDisabled={isLoading} variant={"secondary"}>
                  Forgot password
                  <Box position="absolute" right={4}>
                    <FontAwesomeIcon icon={faUnlockKeyhole} aria-hidden="true" />
                  </Box>
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </>
  );
}
