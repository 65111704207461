import { Box, Heading, Input, Stack, Text } from "@chakra-ui/react";

export default function FileDropper({ filetype, onSelected }) {
  return (
    <Box
      borderColor="gray.300"
      borderStyle="dashed"
      borderWidth="2px"
      rounded="md"
      transition="all 150ms ease-in-out"
      _hover={{
        shadow: "md",
      }}
      cursor={"pointer"}
      height={150}
      initial="rest"
      animate="rest"
    >
      <Box position="relative" height="100%" width="100%">
        <Box position="absolute" top="0" left="0" height="100%" width="100%" display="flex" flexDirection="column">
          <Stack height="100%" width="100%" display="flex" alignItems="center" justify="center" spacing="4">
            <Stack p="8" textAlign="center" spacing="1">
              <Heading fontSize="lg" color="gray.700" fontWeight="bold">
                Drop {filetype} file here
              </Heading>
              <Text fontWeight="light">or click to upload</Text>
            </Stack>
          </Stack>
        </Box>
        <Input
          type="file"
          height="100%"
          width="100%"
          position="absolute"
          top="0"
          left="0"
          opacity="0"
          aria-hidden="true"
          onChange={(e) => onSelected(e.target.files[0])}
        />
      </Box>
    </Box>
  );
}
