import {
  Button,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { COLLECTION } from "data/enum";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { useEffect } from "react";

export default function PatchNotesModal({ disclosure, currentUser }) {
  const currentPatchNotesVersion = "1.0.0";

  useEffect(() => {
    const latestUserVersion = currentUser?.LatestVersion ?? "0.0.0";
    if (currentPatchNotesVersion > latestUserVersion) {
      disclosure.onOpen();

      const db = getFirestore();
      updateDoc(doc(db, COLLECTION.USERS, currentUser.Email), { LatestVersion: currentPatchNotesVersion });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal size={"md"} onClose={disclosure.onClose} isOpen={disclosure.isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={0}>Patch notes ({currentPatchNotesVersion})</ModalHeader>
        <ModalCloseButton />
        <ModalBody mb={4}>
          <Text mb={4}>In this update, we've updated the dashboard, enhanced the activity log and AI Analyzer, and introduced version tracking.</Text>
          <UnorderedList mb={4}>
            <ListItem>Started tracking versions. Will start at 1.0.0 for simplicity.</ListItem>
            <ListItem>Updated dashboard with Patch Notes</ListItem>
            <ListItem>Increased and improved activity log</ListItem>
            <ListItem>Updated storing numbers and data across entire backend (10%-50% faster load times)</ListItem>
            <ListItem>Updated uploading call sheets for AI Analyzer (95% faster upload)</ListItem>
            <ListItem>Updated AI Analyzer for improved performance, especially with slower computers or larger datasets</ListItem>
            <ListItem>Updated upload indicator to properly show data being transferred</ListItem>
          </UnorderedList>
          <Text fontSize={"small"}>* Not all releases will have patch notes</Text>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button onClick={disclosure.onClose} variant={"secondary"}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
