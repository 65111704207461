import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Stack, Text, useBoolean, useToast } from "@chakra-ui/react";
import { faKeySkeleton } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmPasswordReset, getAuth, signInWithEmailAndPassword, verifyPasswordResetCode } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import KameleontSVG from "../../components/Logo/Kameleont";
import { BrandDivider } from "../../components/Miscellaneous/Brand";

export default function ResetPassword() {
  let [searchParams] = useSearchParams();

  const [isLoading, setLoading] = useBoolean();
  const [resetFailed, setResetFailed] = useBoolean();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const toast = useToast();

  const oobCode = searchParams.get("oobCode");
  const mode = searchParams.get("mode");

  if (!oobCode || !mode || mode !== "resetPassword") navigate("/login");

  useEffect(() => {
    setLoading.on();

    const auth = getAuth();
    verifyPasswordResetCode(auth, oobCode)
      .then((userEmail) => {
        setEmail(userEmail);
        setLoading.off();
      })
      .catch((error) => {
        console.error(error);

        toast({
          title: "Something went wrong.",
          status: "error",
          duration: 2500,
          isClosable: false,
        });

        setTimeout(() => {
          navigate("/login");
        }, 2500);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetPassword = () => {
    if (isLoading) return;

    setLoading.on();
    setResetFailed.off();

    const auth = getAuth();
    confirmPasswordReset(auth, oobCode, password)
      .then((resp) => {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password);
      })
      .catch((error) => {
        console.error(error);

        setResetFailed.on();
        setLoading.off();
      });
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      resetPassword();
    }
  };

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <KameleontSVG width={150} height={150} />
          <Heading fontSize={"4xl"}>Change password</Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            Enter your new password.
          </Text>
        </Stack>
        <Box rounded={"lg"} backgroundColor={"white"} boxShadow={"lg"} p={8} minWidth={"380px"}>
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input focusBorderColor="brand.default" name="email" type="email" isRequired value={email} isDisabled={true} isReadOnly={true} />
            </FormControl>
            <FormControl id="password" onSubmit={resetPassword}>
              <FormLabel>Password</FormLabel>
              <Input
                name="password"
                type="password"
                autoComplete="current-password"
                isRequired
                value={password}
                isDisabled={isLoading}
                isInvalid={resetFailed}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDownCapture={onKeyPress}
              />
              <Text marginTop={1} color={"red.500"} display={resetFailed ? "block" : "none"}>
                Invalid password. Please try again.
              </Text>
            </FormControl>
            <BrandDivider />
            <Stack spacing={4}>
              <Button colorScheme="brand" onClick={resetPassword} isDisabled={isLoading} isLoading={isLoading}>
                Update password
                <Box position="absolute" right={4}>
                  <FontAwesomeIcon icon={faKeySkeleton} aria-hidden="true" />
                </Box>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
