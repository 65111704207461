import { Button, Code, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useBoolean, useToast } from "@chakra-ui/react";
import { flattenArray } from "api/array";
import { LogActivityGroup } from "api/firebase";
import { getAuth } from "firebase/auth";
import { doc, getFirestore, writeBatch } from "firebase/firestore";
import { parse } from "papaparse";
import { useEffect, useState } from "react";
import { ACTIVITY, COLLECTION } from "../../../data/enum";
import FileDropper from "../../Miscellaneous/FileDropper";

export default function AddNumbersModal({ Status, isOpen, onOpen, onClose, config, groupId, numbers, currentUser }) {
  const [isLoading, setLoading] = useBoolean();
  const [number, setNumber] = useState("");

  const toast = useToast();

  const getNumbers = (input = number) => (Array.isArray(input) ? input : input.split(",")).map((x) => x.trim().replace("-", "").replace(" ", "")).filter((x) => x.length !== 0);

  const isNumbersValid = (numbers = getNumbers()) => numbers.every((x) => x.length >= 5 && /^[0-9- ]+$/.test(x));

  function isWithinNumbersLimit(inputNumbers = getNumbers()) {
    // 500 is max batch limit
    return numbers.length + inputNumbers.length <= 500;
  }

  function processCSV(file) {
    const failed = () => {
      toast({
        title: "Could not process file!",
        status: "error",
        duration: 2500,
        isClosable: false,
      });
    };

    if (!file?.name?.toLowerCase().includes("csv")) {
      failed();
      return;
    }

    parse(file, {
      complete: (results, file) => {
        let numbers = getNumbers(flattenArray(results.data));
        if (isNumbersValid(numbers)) {
          addNumber(numbers);
        } else {
          failed();
        }
      },
      error: (error, file) => {
        console.error(error);
        failed();
      },
    });
  }

  function addNumber(inputNumbers = getNumbers()) {
    if (isNumbersValid(inputNumbers)) {
      const existingNumbers = new Set(numbers.map((item) => item.Number));
      const input = inputNumbers.filter((number) => !existingNumbers.has(number));

      if (input.length === 0) {
        return null;
      }

      if (!isWithinNumbersLimit(input)) {
        toast({
          title: "Maximum 500 numbers are allowed",
          status: "error",
          duration: 2500,
          isClosable: false,
        });
        return;
      }
      setLoading.on();
      const db = getFirestore();
      const batch = writeBatch(db);
      const auth = getAuth();

      const currentUserEmail = auth.currentUser.email;

      for (let number of input) {
        let numberDoc = doc(db, COLLECTION.ORGANIZATIONS, config.ID, COLLECTION.GROUPS, groupId, COLLECTION.NUMBERS, number);
        batch.set(numberDoc, { Number: number, CreatedBy: currentUserEmail }, { merge: true });
      }

      batch
        .commit()
        .then(() => {
          onClose();

          let numbersLength = input.length;
          let toastMessage = `Added ${numbersLength} number${numbersLength === 1 ? "" : "s"}`;

          toast({
            title: toastMessage,
            status: "success",
            duration: 2500,
            isClosable: false,
          });

          if (numbersLength > 0) LogActivityGroup(config.ID, groupId, ACTIVITY.ACTION.CREATED, ACTIVITY.TYPE.NUMBER, input, currentUser);
        })
        .catch((e) => {
          console.error(e);

          let numbersLength = input.length;
          let toastMessage = `Failed to add number${numbersLength === 1 ? "" : "s"}`;

          toast({
            title: toastMessage,
            status: "error",
            duration: 2500,
            isClosable: false,
          });
        })
        .finally(setLoading.off);
    }
  }

  useEffect(() => {
    onClose();
    // eslint-disable-next-line
  }, [Status]);

  useEffect(() => {
    setNumber("");
  }, [isOpen]);

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addNumber();
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered closeOnEsc={!isLoading} closeOnOverlayClick={!isLoading}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={0}>Add numbers</ModalHeader>
        <ModalCloseButton isDisabled={isLoading} />
        <ModalBody>
          <Text marginBottom={4}>Enter one or multiple telephone numbers. Use commas to separate numbers.</Text>
          <FormControl isInvalid={!isNumbersValid()} id="numbers-input" mt={4} mb={4}>
            <FormLabel>Title</FormLabel>
            <Input value={number} onChange={(e) => setNumber(e.target.value)} isDisabled={isLoading} placeholder="070 XXX XX XX" size="md" type="tel" autoFocus isInvalid={!isNumbersValid()} onKeyDownCapture={onKeyPress} />
            <FormErrorMessage>Invalid number(s)</FormErrorMessage>
          </FormControl>

          <Text marginBottom={4} marginTop={4}>
            You also have the option to import from a .csv file. The file should be in the format of <Code>Number,Number</Code>.
          </Text>
          <FileDropper filetype={".csv"} onSelected={processCSV} />
        </ModalBody>
        <ModalFooter gap={4}>
          <Button onClick={onClose} isDisabled={isLoading} variant={"secondary"}>
            Cancel
          </Button>
          <Button onClick={() => addNumber()} isLoading={isLoading} colorScheme="brand">
            Add numbers
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
