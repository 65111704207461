export const grey = "#bfbaba";
export const lightgrey = "#e3dede";
export const lightlightgrey = "#ededed";
export const lightlightlightgrey = "#f0f0f0";
export const darkgrey = "#787474";
export const lightblue = "#72addb";
export const lightgreen = "#6dd17d";
export const lighttableblue = "#EDF2F7";
export const greyblue = "#bddff0";
export const accentgrey = "#bab1b1";
export const black = "#090a19";
export const green = "#20ba59";
export const white = "#ffffff";
export const danger = "#e03333";
export const warning = "#edb53b";
export const teams = "#00a1f1";
export const discord = "#7289da";
export const yellow = "#f5ab0a";
export const mapColor = (alpha = 0) => `rgba(21, 163, 72, ${alpha})`;
