import { faClock, faCloudQuestion, faThumbsDown, faThumbsUp } from "@fortawesome/pro-duotone-svg-icons";
import EniroSVG from "../components/Logo/Eniro";
import HittaSVG from "../components/Logo/Hitta";
import HundraSVG from "../components/Logo/Hundra";

export const COLLECTION = {
  ORGANIZATIONS: "Organizations",
  MEMBEROF: "MemberOf",
  SERVICES: "Services",
  NUMBERS: "Numbers",
  GROUPS: "Groups",
  STATISTICS: "Statistics",
  HISTORY: "History",
  USERS: "Users",
  ACTIVITY: "Activity",
  SCHEDULE: "Schedule",
  INVOICES: "Invoices",
  CONFIGURATION: "Configuration",
  PHONECALLS: "Phonecalls",
  FILES: "Files",
  ANALYZEDNUMBERS: "AnalyzedNumbers",
  ANALYZEDGROUPEDNUMBERS: "AnalyzedGroupedNumbers",
  ANALYZEDPATTERNNUMBERS: "AnalyzedPatternNumbers",
};

export const DOC = {
  ENIRO: "Eniro",
  HITTA: "Hitta",
};

export const ROLES = {
  OWNER: "OWNER",
  ADMIN: "ADMIN",
  USER: "USER",
};

export const HIDDEN_ROLES = {
  SUPPORT: "SUPPORT",
};

export const STATUS = {
  SUCCESS: { VALUE: "SUCCESS", COLOR: "#0c9c46", TOOLTIP: "The number is not marked as spam", ICON: faThumbsUp },
  FAILED: { VALUE: "FAILED", COLOR: "#CC0000", TOOLTIP: "The number is marked as spam", ICON: faThumbsDown },
  UNKNOWN: { VALUE: "UNKNOWN", COLOR: "#FF8800", TOOLTIP: "Could not check number", ICON: faCloudQuestion },
  WAITING: { VALUE: "WAITING", COLOR: "#C2C0C0", TOOLTIP: "Waiting for next run", ICON: faClock },
  QUEUE: { VALUE: "QUEUE", COLOR: "#0099CC", TOOLTIP: "The number will be processed shortly", ICON: faClock },
};

export const COLORS = {
  IS_VERISURE: "#1184d6",
};

export const SERVICES = {
  ENIRO: {
    ID: "Eniro",
    SVG: <EniroSVG height={24} />,
    Value: "Eniro",
  },
  HITTA: {
    ID: "Hitta",
    SVG: <HittaSVG height={24} />,
    Value: "Hitta",
  },
  HUNDRA: {
    ID: "Hundra",
    SVG: <HundraSVG height={24} />,
    Value: "Other",
  },

  toArray: function () {
    return Object.values(this).filter((x) => typeof x !== "function");
  },
};

// List of packages
export const PACKAGES = {
  START: {
    Name: "Start",
    Limit: 1000,
    Price: 2500,
  },
  UNLIMITED: {
    Name: "Unlimited",
    Limit: 0,
    Price: 0,
  },
};

// List of weekdays
export const WEEKDAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const NUMBERED_WEEKDAYS = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

// List of hours
export const HOURS = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

// Menu items
export const MENU_ITEM = {
  DASHBOARD: 0,
  NUMBER_GROUPS: 1,
  NUMBER_SCRAMBLER: 2,
  MACHINE_LEARNING: 3,
  INVOICES: 4,
  SETTINGS: 5,
};

export const ACTIVITY = {
  ACTION: {
    ADDED: "ADDED",
    CREATED: "CREATED",
    UPDATED: "UPDATED",
    DELETED: "DELETED",
    ENABLED: "ENABLED",
    DISABLED: "DISABLED",
    STARTED: "STARTED",
  },
  TYPE: {
    ORGANIZATION: "ORGANIZATION",
    GROUP: "GROUP",
    NUMBER: "NUMBER",
    SCHEDULE: "SCHEDULE",
    CONFIG: "CONFIG",
    USER: "USER",
    PHONECALLS: "PHONECALLS",
    FILES: "FILES",
  },
  USER: {
    SYSTEM: "SYSTEM",
  },
};

// Status for group
export const GROUPSTATUS = {
  IDLE: "IDLE",
  QUEUE: "QUEUE",
  RUNNING: "RUNNING",
};

export const TABS = {
  ANALYZEDNUMBERS: "ANALYZEDNUMBERS",
  ANALYZEDGROUPEDNUMBERS: "ANALYZEDGROUPEDNUMBERS",
  ANALYZEDPATTERNNUMBERS: "ANALYZEDPATTERNNUMBERS",
};
