import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Input,
  Select,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LogActivityOrganization } from "api/firebase";
import { lightgrey, lightlightgrey, white } from "colors";
import { BrandDivider, CardDivider } from "components/Miscellaneous/Brand";
import { Card } from "components/Miscellaneous/Cards";
import { ACTIVITY, HIDDEN_ROLES, ROLES } from "data/enum";
import dayjs from "dayjs";
import { getAuth } from "firebase/auth";
import { Timestamp, deleteDoc, doc, getDoc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { COLLECTION } from "../../data/enum";

export default function Settings(props) {
  return (
    <>
      <VStack padding={8} width={"100%"} gap={4} alignItems={"flex-start"}>
        <VStack width={"100%"} alignItems={"flex-start"} justifyContent={"flex-start"}>
          <HStack justifyContent={"space-between"} alignItems={"flex-start"} width={"100%"}>
            <VStack alignItems={"flex-start"} justifyContent={"flex-start"}>
              <Heading size={"lg"}>Settings</Heading>
              <Text>Update different types of settings</Text>
            </VStack>
          </HStack>
        </VStack>
        <CardDivider />
        <Card title={"General settings"} text={"Settings not related to account or organization."}>
          <MenuSettings {...props} />
        </Card>
        <Card title={"Account settings"} text={"Settings for your account."}>
          <UserSettings {...props} />
        </Card>
        <Card
          title={"Organization settings"}
          text={
            "If you're adding a user and they don't have an account yet, don't worry, we'll create one for them. Just give them a heads-up to set up a new password on the login page after you've added them."
          }>
          <OrganizationSettings {...props} />
        </Card>
      </VStack>
    </>
  );
}

function MenuSettings({ currentUser }) {
  async function updateSetting(key, value) {
    updateDoc(currentUser._ref, {
      [`Settings.${key}`]: value,
    });
  }

  const foldersFirst = currentUser?.Settings?.FoldersFirst ?? true;
  const toggleFoldersFirst = () => updateSetting("FoldersFirst", !foldersFirst);

  const expandedScreening = currentUser?.Settings?.ExpandedScreening ?? false;
  const toggleExpandedScreening = () => updateSetting("ExpandedScreening", !expandedScreening);

  return (
    <>
      <VStack gap={4} width={"100%"} alignItems={"flex-end"}>
        <VStack gap={2} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <MenuSettingsItem
            title={"Folders first"}
            text={"Sorts folders first in the 'Number Screening' list."}
            isChecked={foldersFirst}
            onChecked={toggleFoldersFirst}
          />
          <MenuSettingsItem
            title={"Expanded screening"}
            text={"Expands 'Number Screening' list to easier manage many number groups."}
            isChecked={expandedScreening}
            onChecked={toggleExpandedScreening}
          />
        </VStack>
      </VStack>
      <BrandDivider marginY={0} />
    </>
  );
}

function MenuSettingsItem({ title, text, isChecked, onChecked }) {
  return (
    <HStack
      justifyContent={"space-between"}
      width={"100%"}
      backgroundColor={lightlightgrey}
      borderRadius={10}
      padding={4}
      borderColor={lightgrey}
      borderWidth={2}>
      <VStack alignItems={"flex-start"}>
        <Text fontWeight={"bold"} fontSize={"md"}>
          {title}
        </Text>
        <Text>{text}</Text>
      </VStack>
      <Switch isChecked={isChecked} onChange={onChecked} colorScheme={"brand"} size={"lg"} />
    </HStack>
  );
}

function UserSettings({ config, currentUser }) {
  const [isLoading, setLoading] = useBoolean();
  const [nameError, setNameError] = useState(null);
  const [name, setName] = useState(currentUser?.Name ?? "");

  const toast = useToast();

  const maxLengthName = 20;

  const handleInputChangeName = (event) => {
    setName(event.target.value);
  };

  const validateName = () => {
    if (name.length > maxLengthName) {
      setNameError(`Name must have maximum ${maxLengthName} characters`);
    } else {
      setNameError(null);
      return true;
    }
    return false;
  };

  function updateUser() {
    const valid = validateName();

    if (valid) {
      setLoading.on();

      const db = getFirestore();

      // Update both user object and current organization object so the name syncs instantly
      Promise.all([
        updateDoc(doc(db, COLLECTION.USERS, currentUser.Email), {
          Name: name.trim(),
        }),
        updateDoc(doc(db, COLLECTION.USERS, currentUser.Email, COLLECTION.MEMBEROF, config.ID), {
          Name: name.trim(),
        }),
      ])
        .then(() => {
          toast({
            title: `Updated name`,
            status: "success",
            duration: 2500,
            isClosable: false,
          });
        })
        .catch((e) => {
          console.error(e);

          toast({
            title: "Failed to update name",
            status: "error",
            duration: 2500,
            isClosable: false,
          });
        })
        .finally(() => setLoading.off());
    }
  }

  function signOut() {
    let auth = getAuth();
    auth.signOut();
  }

  return (
    <>
      <VStack
        gap={4}
        width={"100%"}
        alignItems={"flex-end"}
        backgroundColor={lightlightgrey}
        borderRadius={10}
        padding={4}
        borderColor={lightgrey}
        borderWidth={2}>
        <HStack gap={4} width={"100%"} justifyContent={"center"} alignItems={"center"}>
          <Avatar name={currentUser?.Name ?? currentUser?.Email} size={"2xl"} />
          <VStack gap={2} width={"100%"} marginBottom={4}>
            <FormControl isInvalid={nameError !== null} id="name-input">
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                value={name}
                onChange={handleInputChangeName}
                onBlur={validateName}
                isDisabled={isLoading}
                backgroundColor={white}
                placeholder="John Smith"
                size="md"
                autoFocus
              />
              <FormErrorMessage>{nameError}</FormErrorMessage>
            </FormControl>
            <FormControl id="role-input">
              <FormLabel>Role</FormLabel>
              <Select value={currentUser.Role} variant={"secondary"} disabled={true} fontWeight={"bold"} width={"100%"}>
                <option key={currentUser.Role} value={currentUser.Role}>
                  {currentUser.Role}
                </option>
              </Select>
            </FormControl>
          </VStack>
        </HStack>
        <HStack>
          <Button onClick={signOut} isLoading={isLoading} isDisabled={isLoading} variant="secondary">
            Sign out
          </Button>
          <Button onClick={updateUser} isLoading={isLoading} isDisabled={isLoading || name === currentUser?.Name} colorScheme="brand">
            Update
          </Button>
        </HStack>
      </VStack>
      <BrandDivider marginY={0} />
    </>
  );
}

function OrganizationSettings({ config, currentUser, groupUsers }) {
  const [isLoading, setLoading] = useBoolean();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);

  const toast = useToast();

  const minLengthEmail = 5;

  const handleInputChangeEmail = (event) => {
    setEmail(event.target.value.trim());
  };

  const validateEmail = () => {
    if (email.trim() === "") {
      setEmailError("Email cannot be empty");
      return false;
    } else if (email.length < minLengthEmail) {
      setEmailError(`Email must have minimum ${minLengthEmail} characters`);
      return false;
    } else if (!email.includes("@") || !email.includes(".")) {
      setEmailError(`Email is not in correct format`);
      return false;
    } else {
      setEmailError(null);
      return true;
    }
  };

  async function addUser() {
    const valid = validateEmail();

    if (valid) {
      setLoading.on();
      const db = getFirestore();
      const userDoc = await getDoc(doc(db, COLLECTION.USERS, email));

      if (!userDoc.exists()) {
        await setDoc(userDoc.ref, { Email: email });
      }

      const organizationDoc = await getDoc(doc(userDoc.ref, COLLECTION.MEMBEROF, config.ID));

      if (organizationDoc.exists()) {
        toast({
          title: "User is already in the organization",
          status: "error",
          duration: 3000,
          isClosable: false,
        });
        setLoading.off();

        return null;
      }

      setDoc(organizationDoc.ref, { OrganizationId: config.ID, JoinedAt: Timestamp.now(), Role: ROLES.USER, Email: email })
        .then(() => {
          toast({
            title: `Added user to organization`,
            status: "success",
            duration: 2500,
            isClosable: false,
          });

          LogActivityOrganization(config.ID, ACTIVITY.ACTION.ADDED, ACTIVITY.TYPE.USER, email, currentUser);

          setEmail("");
        })
        .catch((e) => {
          console.error(e);

          toast({
            title: "Failed to add user to organization",
            status: "error",
            duration: 2500,
            isClosable: false,
          });
        })
        .finally(() => {
          setLoading.off();
          setEmailError(null);
        });
    }
  }

  function removeUser(userEmail) {
    setLoading.on();
    const db = getFirestore();

    deleteDoc(doc(db, COLLECTION.USERS, userEmail, COLLECTION.MEMBEROF, config.ID))
      .then(() => {
        toast({
          title: `Removed user from organization`,
          status: "success",
          duration: 2500,
          isClosable: false,
        });

        LogActivityOrganization(config.ID, ACTIVITY.ACTION.DELETED, ACTIVITY.TYPE.USER, userEmail, currentUser);
      })
      .catch((e) => {
        console.error(e);

        toast({
          title: "Failed to remove user from organization",
          status: "error",
          duration: 2500,
          isClosable: false,
        });
      })
      .finally(() => {
        setLoading.off();
      });
  }

  const handleRoleChange = (event, userEmail) => {
    const role = event.target.value;
    if (!Object.values(ROLES).includes(role)) {
      return null;
    }

    setLoading.on();
    const db = getFirestore();

    updateDoc(doc(db, COLLECTION.USERS, userEmail, COLLECTION.MEMBEROF, config.ID), { Role: role })
      .then(() => {
        toast({
          title: `Updated role`,
          status: "success",
          duration: 2500,
          isClosable: false,
        });

        LogActivityOrganization(config.ID, ACTIVITY.ACTION.UPDATED, ACTIVITY.TYPE.USER, { Email: userEmail, Role: role }, currentUser);
      })
      .catch((e) => {
        console.error(e);

        toast({
          title: "Failed to update role of user",
          status: "error",
          duration: 2500,
          isClosable: false,
        });
      })
      .finally(() => {
        setLoading.off();
      });
  };

  function getRoleWeight(role) {
    switch (role) {
      case "SUPPORT":
        return 4;
      case "OWNER":
        return 3;
      case "ADMIN":
        return 2;
      case "USER":
        return 1;
      default:
        return 0;
    }
  }

  return (
    <>
      <VStack width={"100%"} backgroundColor={lightlightgrey} borderRadius={10} padding={4} borderColor={lightgrey} borderWidth={2}>
        <FormControl isInvalid={emailError !== null} id="email-input" mb={4}>
          <HStack>
            <Input
              type="text"
              value={email}
              onChange={handleInputChangeEmail}
              isDisabled={isLoading}
              placeholder="Email address"
              size="md"
              backgroundColor={white}
            />
            <Button onClick={addUser} isDisabled={isLoading} isLoading={isLoading} colorScheme="brand">
              Add user
            </Button>
          </HStack>
          <FormErrorMessage>{emailError}</FormErrorMessage>
        </FormControl>
        <Box borderWidth={2} borderRadius={10} width={"100%"} backgroundColor={white}>
          <Table variant="striped" margin={"auto"}>
            <Thead>
              <Tr>
                <Th>Picture</Th>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Joined</Th>
                <Th>Role</Th>
                {currentUser.isAdmin && <Th>Action</Th>}
              </Tr>
            </Thead>
            <Tbody>
              {groupUsers
                .sort((a, b) => getRoleWeight(b.Role) - getRoleWeight(a.Role))
                .filter((user) => (!currentUser.isSupport ? user.Role !== HIDDEN_ROLES.SUPPORT : true))
                .map((user) =>
                  user.Role === HIDDEN_ROLES.SUPPORT ? (
                    <Tr key={user.Email}>
                      <Td>
                        <Avatar name={user?.Name ?? user.Email} size={"sm"} src={user?.Logo} />
                      </Td>
                      <Td>
                        <Text fontSize={"sm"}>{user?.Name}</Text>
                      </Td>
                      <Td>
                        <Text fontSize={"sm"} fontWeight={"bold"}>
                          {user.Email}
                        </Text>
                      </Td>
                      <Td></Td>
                      <Td width={40}>
                        <Select
                          value={HIDDEN_ROLES.SUPPORT}
                          size={"sm"}
                          onChange={null}
                          variant={"secondary"}
                          disabled={true}
                          fontWeight={"bold"}
                          width={"100%"}>
                          <option key={HIDDEN_ROLES.SUPPORT} value={HIDDEN_ROLES.SUPPORT}>
                            {HIDDEN_ROLES.SUPPORT}
                          </option>
                        </Select>
                      </Td>
                      <Td></Td>
                    </Tr>
                  ) : (
                    <Tr key={user.Email}>
                      <Td>
                        <Avatar name={user?.Name ?? user.Email} size={"sm"} src={user?.Logo} />
                      </Td>
                      <Td>
                        <Text fontSize={"sm"}>{user?.Name}</Text>
                      </Td>
                      <Td>
                        <Text fontSize={"sm"} fontWeight={"bold"}>
                          {user.Email}
                        </Text>
                      </Td>
                      <Td>{user?.JoinedAt && <Text fontSize={"sm"}>{dayjs(user.JoinedAt.toDate()).format("DD MMMM YYYY")}</Text>}</Td>
                      <Td width={40}>
                        <Select
                          value={user?.Role}
                          size={"sm"}
                          onChange={(event) => handleRoleChange(event, user.Email)}
                          variant={"secondary"}
                          disabled={isLoading || !currentUser.isAdmin || user.Role === ROLES.OWNER || user.Email === currentUser.Email}
                          fontWeight={"bold"}
                          width={"100%"}>
                          {(user?.Role === ROLES.OWNER ? Object.values(ROLES) : Object.values(ROLES).filter((role) => role !== ROLES.OWNER)).map((role) => (
                            <option key={role} value={role}>
                              {role}
                            </option>
                          ))}
                        </Select>
                      </Td>
                      <Td>
                        <Tooltip isDisabled={user.Role !== ROLES.OWNER} label={"Cannot delete Owner"}>
                          <Button
                            variant={!currentUser.isAdmin || user.Role === ROLES.OWNER ? "secondary" : "danger"}
                            size={"sm"}
                            iconSpacing={3}
                            rightIcon={<FontAwesomeIcon icon={faTrash} />}
                            isDisabled={!currentUser.isAdmin || user.Role === ROLES.OWNER || isLoading}
                            onClick={currentUser.isAdmin && user.Role !== ROLES.OWNER ? () => removeUser(user.Email) : null}>
                            Remove user
                          </Button>
                        </Tooltip>
                      </Td>
                    </Tr>
                  )
                )}
            </Tbody>
          </Table>
        </Box>
      </VStack>
    </>
  );
}
