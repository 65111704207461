import { Text } from "@chakra-ui/react";
import { danger } from "colors";
import { GROUPSTATUS } from "data/enum";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { calculateNextJobInSeconds } from "../../api/time";

export function Counter({ config, Schedule, Status }) {
  const [seconds, setSeconds] = useState(null);
  const [scheduled, setScheduled] = useState(false);

  useEffect(() => {
    recalculateSecondsUntilNextJob();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Schedule]);

  useEffect(() => {
    window.addEventListener("focus", recalculateSecondsUntilNextJob);
    return () => window.removeEventListener("focus", recalculateSecondsUntilNextJob);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setScheduled(false);
  }, [Status]);

  function recalculateSecondsUntilNextJob() {
    let seconds = calculateNextJobInSeconds(Schedule);
    setSeconds(seconds);
  }

  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      if (seconds === 0) {
        setScheduled(true);
      }
      recalculateSecondsUntilNextJob();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  const formatDuration = (totalSeconds) => {
    const dur = dayjs.duration(totalSeconds, "seconds");
    const totalHours = Math.floor(totalSeconds / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = dur.minutes().toString().padStart(2, "0");
    const seconds = dur.seconds().toString().padStart(2, "0");

    return `${totalHours}:${minutes}:${seconds}`;
  };

  const getText = () => {
    if (Status !== GROUPSTATUS.IDLE) {
      return Status;
    }

    if (scheduled || seconds === 0) {
      return GROUPSTATUS.QUEUE;
    }

    return Object.keys(Schedule).length > 0 ? formatDuration(seconds) : "Offline";
  };

  return (
    <Text fontSize={"2xl"} as={"b"} color={Object.keys(Schedule).length === 0 ? danger : null}>
      {getText()}
    </Text>
  );
}
