import {
  Box,
  Button,
  Checkbox,
  Fade,
  Flex,
  HStack,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import {
  faArrowDown,
  faArrowUp,
  faChartSimple,
  faCompressAlt,
  faExpandAlt,
  faFileUpload,
  faFilter,
  faFilterCircleXmark,
  faList,
  faPhone,
  faReply,
  faSort,
  faUpload,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generateRandomNumberString, rateCountys } from "api/analyzer";
import { black, grey, lightgrey, lightlightgrey, lightlightlightgrey, lighttableblue, white } from "colors";
import { CardDivider } from "components/Miscellaneous/Brand";
import { CardFileButton, CardWithHeaderElement, CardWithSubHeaderElement } from "components/Miscellaneous/Cards";
import { MapOfSweden } from "components/Miscellaneous/Interactive";
import dayjs from "dayjs";
import { getApp } from "firebase/app";
import { collection, getFirestore, onSnapshot } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import Loading from "pages/Miscellaneous/Loading";
import { useEffect, useState } from "react";
import { COLLECTION, STATUS } from "../../data/enum";
import ManageFilesModal from "./Modal/ManageFilesModal";
import UploadFilesModal from "./Modal/UploadFilesModal";

export default function Analyzer(props) {
  const [files, setFiles] = useState(null);
  const [analyzedNumbers, setAnalyzedNumbers] = useState(null);
  const [analyzedNumbersOffset, setAnalyzedNumbersOffset] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [expandedCountys, setExpandedCountys] = useState([]);
  const [sortBy, setSortBy] = useState("rating");
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExtra, setIsLoadingExtra] = useState(false);
  const [isCountingDown, setIsCountingDown] = useState(false);

  const sortables = ["calls", "responses", "hitrate", "rating"];

  const [filter, setFilter] = useState(() => {
    let object = {};
    for (let item of sortables) {
      object[item] = 0;
    }
    return object;
  });
  const [patternFilter, setPatternFilter] = useState({ three: true, four: true });

  const { config, currentUser } = props;

  const uploadFilesDisclosure = useDisclosure();
  const manageFilesDisclosure = useDisclosure();

  const fetchData = (index = tabIndex) => {
    const functions = getFunctions(getApp(), "europe-west1");
    const getAnalyzedNumbers = httpsCallable(functions, "GetAnalyzedNumbers");

    getAnalyzedNumbers({ organizationId: config.ID, tabIndex: index, offset: analyzedNumbersOffset, expandedCountys, sortBy, filter, patternFilter })
      .then((request) => {
        setAnalyzedNumbers(request.data);
      })
      .finally(() => {
        setIsAnalyzing(false);
        setIsLoading(false);
        setIsLoadingExtra(false);
      });
  };

  useEffect(() => {
    setIsLoadingExtra(true);
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setIsLoadingExtra(true);
      fetchData();
    }
    // eslint-disable-next-line
  }, [analyzedNumbersOffset, expandedCountys, sortBy, filter, patternFilter]);

  const onChangeTabIndex = (index) => {
    setTabIndex(index);
    setAnalyzedNumbersOffset(10);
    setExpandedCountys([]);

    setIsLoading(true);
    setAnalyzedNumbers(null);

    fetchData(index);
  };

  const showMore = () => {
    setAnalyzedNumbersOffset((_offset) => _offset + 10);
  };

  useEffect(() => {
    const timeToProcess = 30; // 30 seconds must pass since the last file change to access the analyzer

    let latestFile = (files ?? []).length > 0 ? files.sort((a, b) => b.UploadedAt - a.UploadedAt)[0] : null;

    let lastFileUpdate = config?.LastFileUpdate;
    let secondsSinceFileChange = null;
    if (latestFile?.UploadedAt?.hasOwnProperty("seconds")) {
      secondsSinceFileChange = dayjs().diff(dayjs.unix(latestFile.UploadedAt.seconds), "seconds");
    }

    if (lastFileUpdate?.hasOwnProperty("seconds")) {
      let secondsDifference = dayjs().diff(dayjs.unix(lastFileUpdate.seconds), "seconds");
      if (secondsSinceFileChange == null || secondsDifference < secondsSinceFileChange) {
        secondsSinceFileChange = secondsDifference;
      }
    }

    const negativeDifference = secondsSinceFileChange ? secondsSinceFileChange - timeToProcess : 0;

    let timeoutId;

    setIsAnalyzing(negativeDifference < 0 || (files ?? []).some((phoneCall) => phoneCall.Processing));

    if (negativeDifference < 0) {
      setIsCountingDown(true);
      console.log("Waiting for", -negativeDifference, "seconds before executing the function.");

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        fetchData();
        setIsCountingDown(false);
      }, -negativeDifference * 1000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    // eslint-disable-next-line
  }, [config, files]);

  useEffect(() => {
    const db = getFirestore();
    const filesCollection = collection(db, COLLECTION.ORGANIZATIONS, config.ID, COLLECTION.FILES);
    const subscription = onSnapshot(filesCollection, (filesSnapshot) => {
      setFiles(filesSnapshot.docs.map((doc) => ({ ...doc.data(), ref: doc.ref })));

      const shouldAnalyze = filesSnapshot.size !== filesSnapshot.docChanges().length;
      if (shouldAnalyze) {
        setIsAnalyzing(true);
      }
    });

    return subscription;

    // eslint-disable-next-line
  }, []);

  if (!files) {
    return <Loading />;
  }

  const gradientStyle = {
    background: `linear-gradient(to right, transparent 0%, #f7f7f7 300px)`,
  };

  return (
    <>
      <>
        <UploadFilesModal {...uploadFilesDisclosure} {...props} />
        <ManageFilesModal {...manageFilesDisclosure} {...props} files={files} />
      </>
      <VStack padding={8} width={"100%"} gap={4} alignItems={"flex-start"} overflowX={"hidden"}>
        <VStack width={"100%"} alignItems={"flex-start"} justifyContent={"flex-start"}>
          <HStack justifyContent={"space-between"} alignItems={"flex-start"} width={"100%"}>
            <VStack alignItems={"flex-start"} justifyContent={"flex-start"}>
              <Heading size={"lg"}>AI Analyzer</Heading>
              <Text>Analyzes phone calls</Text>
            </VStack>
            <HStack gap={2}>
              {currentUser.isAdmin && (
                <Button
                  colorScheme="brand"
                  iconSpacing={3}
                  rightIcon={<FontAwesomeIcon icon={faUpload} />}
                  onClick={uploadFilesDisclosure.onOpen}
                  isDisabled={isLoading || isAnalyzing}>
                  Upload
                </Button>
              )}
            </HStack>
          </HStack>
        </VStack>
        <CardDivider />
        <CardWithHeaderElement
          title={"Files"}
          headerChildren={
            <Button
              variant={"secondary"}
              iconSpacing={3}
              rightIcon={<FontAwesomeIcon icon={faList} />}
              onClick={manageFilesDisclosure.onOpen}
              isDisabled={isLoading || isAnalyzing || files.length === 0}>
              View all
            </Button>
          }>
          <Box position={"relative"} width={"100%"} overflow={"hidden"}>
            <HStack spacing={6} position={"relative"} width={"auto"} maxWidth={"100%"} overflow={"hidden"} alignItems={"flex-start"}>
              <Box position={"absolute"} top={0} right={0} bottom={0} width={300} zIndex={2} pointerEvents={"none"} style={{ ...gradientStyle }} />
              {(files ?? [])
                .sort((a, b) => b.UploadedAt - a.UploadedAt)
                .slice(0, 4)
                .map((phonecall) => {
                  return (
                    <CardFileButton
                      key={phonecall.UploadedAt}
                      filename={phonecall.Filename}
                      uploader={phonecall.UploadedBy}
                      startDate={
                        phonecall.TimespanStart?.seconds
                          ? dayjs.unix(phonecall.TimespanStart.seconds).format("YYYY-MM-DD")
                          : dayjs(phonecall.TimespanStart).format("YYYY-MM-DD")
                      }
                      endDate={
                        phonecall.TimespanEnd?.seconds
                          ? dayjs.unix(phonecall.TimespanEnd.seconds).format("YYYY-MM-DD")
                          : dayjs(phonecall.TimespanEnd).format("YYYY-MM-DD")
                      }
                      uploadedDate={dayjs.unix(phonecall.UploadedAt?.seconds).format("DD MMMM YYYY")}
                      uploadedTime={dayjs.unix(phonecall.UploadedAt?.seconds).format("HH:mm:ss")}
                      processing={phonecall.Processing}
                    />
                  );
                })}
            </HStack>
          </Box>
        </CardWithHeaderElement>
        <NumbersList
          isCountingDown={isCountingDown}
          isLoading={isLoading}
          isLoadingExtra={isLoadingExtra}
          isAnalyzing={isAnalyzing}
          files={files}
          tabIndex={tabIndex}
          onChange={onChangeTabIndex}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortables={sortables}
          filter={filter}
          setFilter={setFilter}
          patternFilter={patternFilter}
          setPatternFilter={setPatternFilter}
          analyzedNumbers={analyzedNumbers}
          showMore={showMore}
          expandedCountys={expandedCountys}
          setExpandedCountys={setExpandedCountys}
          {...props}
        />
      </VStack>
    </>
  );
}

function NumbersList({
  isCountingDown,
  isLoading,
  isLoadingExtra,
  isAnalyzing,
  files,
  tabIndex,
  onChange,
  sortBy,
  setSortBy,
  sortables,
  filter,
  setFilter,
  patternFilter,
  setPatternFilter,
  analyzedNumbers,
  setExtraColumn,
  showMore,
  expandedCountys,
  setExpandedCountys,
}) {
  useEffect(() => {
    setExtraColumn(
      isAnalyzing || isLoading || isCountingDown || tabIndex !== 1 ? null : (
        <Fade in={true}>
          <MapOfSweden countys={rateCountys(analyzedNumbers.data)} />
        </Fade>
      )
    );
    // eslint-disable-next-line
  }, [analyzedNumbers, isAnalyzing, isCountingDown]);

  function moveSortByToNext() {
    const currentIndex = sortables.indexOf(sortBy);

    const nextIndex = (currentIndex + 1) % sortables.length;
    setSortBy(sortables[nextIndex]);
  }

  function CustomTab({ title, index }) {
    return (
      <Tab
        backgroundColor={tabIndex === index ? lightgrey : lightlightlightgrey}
        borderWidth={0}
        borderStyle={"solid"}
        borderColor={lightlightgrey}
        opacity={tabIndex === index ? 1 : 0.75}
        borderTopRadius={6}>
        <Heading as={"h4"} size={"sm"} textColor={black} fontWeight={tabIndex === index ? "bold" : "normal"} minWidth={150}>
          {title}
        </Heading>
      </Tab>
    );
  }

  function CustomTabPanel({ element }) {
    return <TabPanel>{element}</TabPanel>;
  }

  const tabs = [
    {
      title: "Patterns",
      element: <PatternNumbers analyzedPatternNumbers={analyzedNumbers} showMore={showMore} isLoadingExtra={isLoadingExtra} patternFilter={patternFilter} />,
    },
    {
      title: "Counties",
      element: (
        <GroupedNumbers
          analyzedGroupedNumbers={analyzedNumbers}
          isAnalyzing={isAnalyzing}
          isLoadingExtra={isLoadingExtra}
          sortBy={sortBy}
          expandedCountys={expandedCountys}
          setExpandedCountys={setExpandedCountys}
        />
      ),
    },
    { title: "Numbers", element: <Numbers analyzedNumbers={analyzedNumbers} showMore={showMore} isLoadingExtra={isLoadingExtra} /> },
  ];

  return (
    <CardWithSubHeaderElement
      title={"Numbers"}
      subTitleChildren={
        <Summary
          data={
            analyzedNumbers?.statistics ?? {
              calls: "-",
              responses: "-",
              hitrate: "-",
            }
          }
          isAnalyzing={isAnalyzing}
        />
      }
      headerChildren={
        <HStack justifyContent={"flex-end"}>
          <Button
            variant={"secondary"}
            iconSpacing={3}
            rightIcon={<FontAwesomeIcon icon={faSort} />}
            onClick={moveSortByToNext}
            isDisabled={isLoading || isLoadingExtra || isAnalyzing || files.length === 0}>
            Sort by: {sortBy.charAt(0).toUpperCase() + sortBy.slice(1)}
          </Button>
        </HStack>
      }>
      {(files !== null && files.length === 0) || isCountingDown || isLoading || isAnalyzing || (files ?? []).some((file) => file.Delete || file.Processing) ? (
        <Flex
          borderColor="gray.300"
          borderStyle="dashed"
          borderWidth="2px"
          rounded="md"
          height={180}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}>
          <VStack gap={2}>
            {!isAnalyzing && !isCountingDown && files !== null && files.length === 0 ? (
              <Text as={"b"}>
                <FontAwesomeIcon icon={faFileUpload} size="lg" style={{ marginRight: 2 }} /> Upload a file to get started
              </Text>
            ) : isAnalyzing || isCountingDown ? (
              <VStack gap={2}>
                <Spinner size={"lg"} />
                <Flex direction={"column"} alignItems={"center"}>
                  <Text fontSize={"sm"} fontWeight={"bold"}>
                    {files !== null && files.length === 0 ? "Cleaning up..." : "Processing..."}
                  </Text>
                  <Text fontSize={"sm"}>This can take a while.</Text>
                </Flex>
              </VStack>
            ) : (
              <Spinner size={"lg"} />
            )}
          </VStack>
        </Flex>
      ) : (
        <Tabs colorScheme={"tabs"} marginTop={2} width={"100%"} isLazy={true} lazyBehavior={"unmount"} index={tabIndex} onChange={onChange}>
          <TabList marginX={4}>
            {tabs.map(({ title }, index) => (
              <CustomTab key={index} title={title} index={index} />
            ))}
          </TabList>
          <Box
            backgroundColor={lightlightgrey}
            borderRadius={6}
            paddingTop={4}
            paddingX={4}
            borderColor={lightgrey}
            borderWidth={2}
            borderBottomWidth={0}
            borderStyle={"solid"}>
            <Box padding={4} alignItems={"flex-start"} width={"100%"} backgroundColor={white} borderRadius={10} borderColor={lightgrey} borderWidth={2}>
              <FilterNumbers
                filter={filter}
                setFilter={setFilter}
                patternFilter={patternFilter}
                setPatternFilter={setPatternFilter}
                isPatternsSelected={tabs[tabIndex].title === "Patterns"}
                isLoadingExtra={isLoadingExtra}
              />
            </Box>
          </Box>
          <TabPanels
            variant="enclosed"
            backgroundColor={lightlightgrey}
            borderRadius={6}
            borderTopRadius={0}
            borderColor={lightgrey}
            borderWidth={2}
            borderTopWidth={0}
            borderStyle={"solid"}>
            {tabs.map(({ element }, index) => (
              <CustomTabPanel key={index} element={element} />
            ))}
          </TabPanels>
        </Tabs>
      )}
    </CardWithSubHeaderElement>
  );

  function FilterNumbers({ filter, setFilter, patternFilter, setPatternFilter, isPatternsSelected, isLoadingExtra }) {
    const [_filter, _setFilter] = useState(filter);
    const [_patternFilter, _setPatternFilter] = useState(patternFilter);

    const applyFilter = () => {
      setFilter(_filter);
      setPatternFilter(_patternFilter);
    };

    const resetFilter = () => {
      _setFilter((_prevFilter) => {
        let object = {};
        for (let item of Object.keys(_prevFilter)) {
          object[item] = 0;
        }
        setFilter(object);
        return object;
      });

      setPatternFilter((_prevPatternFilter) => {
        let object = {};
        for (let item of Object.keys(_prevPatternFilter)) {
          object[item] = true;
        }
        return object;
      });
    };

    return (
      <VStack gap={1}>
        <HStack width="100%" gap={4}>
          {Object.keys(filter)
            .filter((item) => item !== "rating")
            .map((key) => (
              <InputCard key={key} sortable={key} filter={_filter} setFilter={_setFilter} />
            ))}
        </HStack>
        <HStack width="100%" justifyContent={"space-between"}>
          <HStack justifyContent="flex-start" width="100%" gap={2}>
            <Button
              variant="secondary"
              iconSpacing={3}
              leftIcon={<FontAwesomeIcon icon={faFilterCircleXmark} />}
              onClick={resetFilter}
              isDisabled={isLoadingExtra}>
              Reset filter
            </Button>
            <Button
              colorScheme="brand"
              iconSpacing={3}
              rightIcon={<FontAwesomeIcon icon={faFilter} />}
              onClick={applyFilter}
              isDisabled={_filter === filter && _patternFilter === patternFilter}
              isLoading={isLoadingExtra}>
              Apply filter
            </Button>
          </HStack>

          <HStack justifyContent="flex-end" width="100%" gap={2}>
            <Checkbox
              isDisabled={!isPatternsSelected}
              isChecked={_patternFilter.three}
              onChange={() => _setPatternFilter((_prevFilter) => ({ ..._prevFilter, three: !_patternFilter.three }))}
              colorScheme="green"
              size="md"
              defaultChecked>
              3 numbered patterns
            </Checkbox>

            <Checkbox
              isDisabled={!isPatternsSelected}
              isChecked={_patternFilter.four}
              onChange={() => _setPatternFilter((_prevFilter) => ({ ..._prevFilter, four: !_patternFilter.four }))}
              colorScheme="green"
              size="md"
              defaultChecked>
              4 numbered patterns
            </Checkbox>
          </HStack>
        </HStack>
      </VStack>
    );
  }

  function InputCard({ sortable, filter, setFilter }) {
    const [number, setNumber] = useState(filter[sortable]);

    useEffect(() => {
      setNumber(filter[sortable]);
      // eslint-disable-next-line
    }, [filter]);

    useEffect(() => {
      if (number !== filter[sortable]) {
        setFilter((_prevFilter) => ({ ..._prevFilter, [sortable]: number }));
      }
      // eslint-disable-next-line
    }, [number]);

    const onChange = (newNumber) => {
      setNumber(newNumber);
    };

    return (
      <Box width="100%">
        <Heading as="h4" size="sm" textColor={black} fontWeight="bold">
          Minimum {sortable}
        </Heading>
        <NumberInput backgroundColor={lighttableblue} borderRadius={6} marginTop={2} value={number.toString()} onChange={onChange}>
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Text fontSize="xs" marginTop={1}>
          Filter on {sortable}
        </Text>
      </Box>
    );
  }

  function PatternNumbers({ analyzedPatternNumbers, showMore }) {
    return (
      <>
        <Box width={"100%"} backgroundColor={white} borderRadius={10} borderColor={lightgrey} borderWidth={2}>
          <TableContainer borderWidth={2} borderRadius={8} width={"100%"} backgroundColor={white}>
            <Table variant={"striped"}>
              <Thead>
                <Tr>
                  <Th>Pattern</Th>
                  <Th>Example</Th>
                  <Th>Calls</Th>
                  <Th>Responses</Th>
                  <Th>Hitrate</Th>
                </Tr>
              </Thead>
              <Tbody>
                {analyzedPatternNumbers.data.map((item, index) => (
                  <Tr key={JSON.stringify(item)}>
                    <Td paddingY={2}>
                      <Text fontSize={"sm"} as={"b"}>
                        {item.key}
                      </Text>
                    </Td>
                    <Td paddingY={2}>
                      <Text fontSize={"sm"} as={"b"}>
                        <Text as={"span"} textColor={black} opacity={0.2}>
                          073
                        </Text>
                        <Text as={"span"} marginX={1} fontWeight={"bold"} textColor={black}>
                          {item.key}
                        </Text>
                        <Text as={"span"} textColor={black} opacity={0.2}>
                          {generateRandomNumberString(7 - item.key.length)}
                        </Text>
                      </Text>
                    </Td>
                    <Td paddingY={2}>
                      <Text fontSize={"sm"} as={"b"}>
                        {item.calls}
                      </Text>
                    </Td>
                    <Td paddingY={2}>
                      <Text fontSize={"sm"} as={"b"}>
                        {item.responses}
                      </Text>
                    </Td>
                    <Td paddingY={2}>
                      <Text fontSize={"sm"} as={"b"}>
                        {item.hitrate}
                      </Text>
                    </Td>
                  </Tr>
                ))}
                {analyzedPatternNumbers.total - analyzedPatternNumbers.length > 0 && (
                  <Tr>
                    <Td paddingY={2} cursor={"pointer"} paddingX={4} onClick={showMore}>
                      <Text fontSize={"sm"}>{analyzedPatternNumbers.total - analyzedPatternNumbers.length} more...</Text>
                    </Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Button
          width={"100%"}
          colorScheme="brand"
          iconSpacing={3}
          rightIcon={<FontAwesomeIcon icon={faArrowDown} />}
          marginTop={4}
          onClick={showMore}
          isLoading={isLoadingExtra}
          isDisabled={analyzedPatternNumbers.total <= analyzedPatternNumbers.length}>
          Show more
        </Button>
      </>
    );
  }

  function GroupedNumbers({ analyzedGroupedNumbers, isAnalyzing, sortBy, expandedCountys, setExpandedCountys }) {
    function expandCounty(county) {
      if (!expandedCountys.includes(county)) {
        setExpandedCountys((prev) => [...prev, county]);
      }
    }

    function collapseCounty(county) {
      const index = expandedCountys.indexOf(county);
      if (index !== -1) {
        const updatedCountys = [...expandedCountys];
        updatedCountys.splice(index, 1);
        setExpandedCountys(updatedCountys);
      }
    }

    return (
      <VStack gap={6} width={"100%"}>
        {Object.values(analyzedGroupedNumbers.data).map(({ county, data, length, total, statistics }) => {
          return (
            <Box width={"100%"} key={county}>
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Heading as={"h2"} size={"md"} marginStart={2} marginBottom={2}>
                  {county}
                </Heading>
                <Flex justifyContent={"flex-end"} paddingBottom={2}>
                  <Summary data={statistics} isAnalyzing={isAnalyzing} />
                  <Button
                    variant={"secondary"}
                    onClick={() => (expandedCountys.includes(county) ? collapseCounty(county) : expandCounty(county))}
                    padding={0}
                    marginStart={2}
                    size={"sm"}
                    isDisabled={isLoadingExtra || total - 10 < 0}>
                    <FontAwesomeIcon icon={expandedCountys.includes(county) ? faCompressAlt : faExpandAlt} />
                  </Button>
                </Flex>
              </Flex>
              <Box width={"100%"} backgroundColor={white} borderRadius={10} borderColor={lightgrey} borderWidth={2}>
                <TableContainer borderWidth={2} borderRadius={8} width={"100%"} backgroundColor={white}>
                  <Table variant={"striped"}>
                    <Thead>
                      <Tr>
                        <Th>Number</Th>
                        <Th>Calls</Th>
                        <Th>Responses</Th>
                        <Th>Hitrate</Th>
                      </Tr>
                    </Thead>
                    <Tbody
                      cursor={total - 10 > 0 ? "pointer" : null}
                      onClick={() => (total - 10 > 0 ? (expandedCountys.includes(county) ? collapseCounty(county) : expandCounty(county)) : null)}>
                      {data.map(({ number, calls, responses, hitrate }) => (
                        <Tr key={county + number}>
                          <Td paddingY={2} cursor={"copy"} pointerEvents={"none"}>
                            <Text fontSize={"sm"} fontWeight={"bold"}>
                              {number}
                            </Text>
                          </Td>
                          <Td paddingY={2}>
                            <Text fontSize={"sm"} fontWeight={"bold"}>
                              {calls}
                            </Text>
                          </Td>
                          <Td paddingY={2}>
                            <Text fontSize={"sm"} fontWeight={"bold"}>
                              {responses}
                            </Text>
                          </Td>
                          <Td paddingY={2}>
                            <Text fontSize={"sm"} fontWeight={"bold"}>
                              {hitrate}
                            </Text>
                          </Td>
                        </Tr>
                      ))}
                      {total - 10 > 0 && (
                        <Tr width={"100%"}>
                          <Td paddingY={2}>
                            <Flex flexDirection={"row"} gap={3}>
                              <FontAwesomeIcon color={black} icon={expandedCountys.includes(county) ? faArrowUp : faArrowDown} />
                              {!expandedCountys.includes(county) && <Text fontSize={"sm"}>{total - length} more...</Text>}
                            </Flex>
                          </Td>
                          <Td></Td>
                          <Td></Td>
                          <Td></Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          );
        })}
      </VStack>
    );
  }

  function Numbers({ analyzedNumbers, showMore }) {
    return (
      <>
        <Box width={"100%"} backgroundColor={white} borderRadius={10} borderColor={lightgrey} borderWidth={2}>
          <TableContainer borderWidth={2} borderRadius={8} width={"100%"} backgroundColor={white}>
            <Table variant={"striped"}>
              <Thead>
                <Tr>
                  <Th>Number</Th>
                  <Th>Calls</Th>
                  <Th>Responses</Th>
                  <Th>Hitrate</Th>
                </Tr>
              </Thead>
              <Tbody>
                {analyzedNumbers.data.map((item, index) => (
                  <Tr key={JSON.stringify(item)}>
                    <Td paddingY={2}>
                      <Text fontSize={"sm"} as={"b"}>
                        {item.number}
                      </Text>
                    </Td>
                    <Td paddingY={2}>
                      <Text fontSize={"sm"} as={"b"}>
                        {item.summary.calls}
                      </Text>
                    </Td>
                    <Td paddingY={2}>
                      <Text fontSize={"sm"} as={"b"}>
                        {item.summary.responses}
                      </Text>
                    </Td>
                    <Td paddingY={2}>
                      <Text fontSize={"sm"} as={"b"}>
                        {item.summary.hitrate}
                      </Text>
                    </Td>
                  </Tr>
                ))}
                {analyzedNumbers.total - analyzedNumbers.length > 0 && (
                  <Tr>
                    <Td paddingY={2} cursor={"pointer"} paddingX={6} onClick={showMore}>
                      <Text fontSize={"sm"}>{analyzedNumbers.total - analyzedNumbers.length} more...</Text>
                    </Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Button
          width={"100%"}
          colorScheme="brand"
          iconSpacing={3}
          rightIcon={<FontAwesomeIcon icon={faArrowDown} />}
          marginTop={4}
          onClick={showMore}
          isLoading={isLoadingExtra}
          isDisabled={analyzedNumbers.total <= analyzedNumbers.length}>
          Show more
        </Button>
      </>
    );
  }

  function Summary({ data, isAnalyzing }) {
    const statuses = [
      { icon: faPhone, color: grey, label: "Calls", key: "calls" },
      { icon: faReply, color: STATUS.SUCCESS.COLOR, label: "Responses", key: "responses" },
      { icon: faChartSimple, color: STATUS.UNKNOWN.COLOR, label: "Hitrate", key: "hitrate" },
    ];

    return (
      <HStack justifyContent={"space-evenly"} gap={2}>
        {statuses.map(({ icon, color, label, key }) => (
          <HStack key={key} borderRadius={10}>
            <HStack>
              {icon && <FontAwesomeIcon icon={icon} color={color} />}
              <Text>
                <Text as="span" fontWeight={"bold"}>
                  {isAnalyzing ? "-" : `${data[key]}${key === "hitrate" ? "%" : ""}`}
                </Text>{" "}
                {label}
              </Text>
            </HStack>
          </HStack>
        ))}
      </HStack>
    );
  }
}
