import { Box, Button, Center, Checkbox, Flex, HStack, Heading, Skeleton, Spinner, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr, VStack, useDisclosure, useToast } from "@chakra-ui/react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faAdd, faBars, faCalendarClock, faCloudQuestion, faEye, faEyeSlash, faFlag, faPhonePlus, faPlay, faSliders, faStar, faTrash, faXmark } from "@fortawesome/pro-duotone-svg-icons";
import { faClockFour, faGear, faTimer, faTruckRampBox } from "@fortawesome/pro-regular-svg-icons";
import { faCheckCircle, faCircleCheck, faCircleQuestion, faCircleXmark, faClock, faQuestion, faStar as faStarSolid, faXmarkCircle, faXmark as faXmarkSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getJobsForDay, getNextJobFormatted } from "api/time";
import { accentgrey, danger, grey, white, yellow } from "colors";
import { CardDivider } from "components/Miscellaneous/Brand";
import { CardWithHeaderElement, CardWithSubHeaderElement, HorizontalIconCard } from "components/Miscellaneous/Cards";
import { Counter } from "components/Miscellaneous/Counter";
import dayjs from "dayjs";
import { arrayRemove, arrayUnion, collection, doc, getFirestore, onSnapshot, updateDoc } from "firebase/firestore";
import Loading from "pages/Miscellaneous/Loading";
import { useEffect, useState } from "react";
import { COLLECTION, COLORS, GROUPSTATUS, NUMBERED_WEEKDAYS, SERVICES, STATUS, WEEKDAYS } from "../../data/enum";
import AddNumbersModal from "./Modal/AddNumbersModal";
import DeleteNumbersModal from "./Modal/DeleteNumbersModal";
import EditGroupModal from "./Modal/EditGroupModal";
import EditScheduleModal from "./Modal/EditScheduleModal";

export default function NumberGroup(props) {
  const [numbers, setNumbers] = useState([]);
  const [isEditingNumbers, setIsEditingNumbers] = useState(false);
  const [isLoadingSnapshot, setIsLoadingSnapshot] = useState(true);

  const editGroupDisclosure = useDisclosure();
  const editConfigDisclosure = useDisclosure();

  const toast = useToast();

  const { config, id, Title, Description, Schedule, Status, currentUser } = props;

  useEffect(() => {
    setIsLoadingSnapshot(true);
    setNumbers([]);

    if (id) {
      const db = getFirestore();
      const numbersCollection = collection(db, COLLECTION.ORGANIZATIONS, config.ID, COLLECTION.GROUPS, id, COLLECTION.NUMBERS);
      const subscription = onSnapshot(numbersCollection, (numbersListSnapshot) => {
        setNumbers(numbersListSnapshot.docs.map((x) => x.data()));
        setIsLoadingSnapshot(false);
      });

      return subscription;
    }
    // eslint-disable-next-line
  }, [id]);

  // The group has not been fully setup by the function yet
  if (!Schedule) {
    return <Loading />;
  }

  async function queueGroup() {
    const db = getFirestore();
    updateDoc(doc(db, COLLECTION.ORGANIZATIONS, config.ID, COLLECTION.GROUPS, id), {
      Status: GROUPSTATUS.QUEUE,
    })
      .then(() => {
        toast({
          title: `Added to queue`,
          status: "success",
          duration: 2500,
          isClosable: false,
        });
      })
      .catch(() => {
        toast({
          title: `Failed to queue`,
          status: "error",
          duration: 2500,
          isClosable: false,
        });
      });
  }

  const isGroupFavorite = (currentUser?.FavoriteGroups ?? []).includes(id);
  const isGroupHidden = (currentUser?.HiddenGroups ?? []).includes(id);

  async function toggleFavoriteGroup() {
    updateDoc(currentUser._ref, {
      FavoriteGroups: isGroupFavorite ? arrayRemove(id) : arrayUnion(id),
    });
  }

  async function toggleHiddenGroup() {
    updateDoc(currentUser._ref, {
      HiddenGroups: isGroupHidden ? arrayRemove(id) : arrayUnion(id),
    });
  }

  function getWeekdayIcon(day) {
    let runToday = day in Schedule;
    let icon;
    let colorStyle;

    if (runToday) {
      icon = faCheckCircle;
      colorStyle = { color: "#34b73c" };
    } else {
      icon = faXmarkCircle;
      colorStyle = { color: "#dd4b4b" };
    }
    return <FontAwesomeIcon icon={icon} style={colorStyle} size="lg" />;
  }

  const organizationServices = SERVICES.toArray().filter((x) => config.Services.includes(x.ID));

  return (
    <>
      <>
        <EditGroupModal {...editGroupDisclosure} {...props} groupId={id} numbers={numbers} currentUser={currentUser} />
        <EditScheduleModal {...editConfigDisclosure} {...props} groupId={id} currentUser={currentUser} />
      </>
      <VStack padding={8} width={"100%"} gap={4} alignItems={"flex-start"}>
        <VStack width={"100%"} alignItems={"flex-start"} justifyContent={"flex-start"}>
          <HStack justifyContent={"space-between"} alignItems={"flex-start"} width={"100%"}>
            <VStack alignItems={"flex-start"} justifyContent={"flex-start"}>
              <Heading size={"lg"}>{Title}</Heading>
              <Text as={Description ? "p" : "i"}>{Description || "No description"}</Text>
            </VStack>
            <HStack gap={2}>
              {currentUser.isSupport && (
                <Tooltip isDisabled={Status === GROUPSTATUS.IDLE && !isEditingNumbers} label={`Unavailable while ${isEditingNumbers ? "editing numbers" : "a job is running"}`}>
                  <Button colorScheme="brand" iconSpacing={3} leftIcon={<FontAwesomeIcon icon={faPlay} />} isDisabled={Status !== GROUPSTATUS.IDLE || isEditingNumbers || isLoadingSnapshot} onClick={Status === GROUPSTATUS.IDLE ? queueGroup : null}>
                    Queue
                  </Button>
                </Tooltip>
              )}
              {currentUser.isAdmin && (
                <Tooltip isDisabled={Status === GROUPSTATUS.IDLE && !isEditingNumbers} label={`Unavailable while ${isEditingNumbers ? "editing numbers" : "a job is running"}`}>
                  <Button colorScheme="brand" variant={"secondary"} iconSpacing={3} rightIcon={<FontAwesomeIcon icon={faSliders} />} isDisabled={Status !== GROUPSTATUS.IDLE || isEditingNumbers || isLoadingSnapshot} onClick={Status === GROUPSTATUS.IDLE ? editGroupDisclosure.onOpen : null}>
                    Group Settings
                  </Button>
                </Tooltip>
              )}
              <Button backgroundColor={isGroupHidden ? grey : null} colorScheme="brand" variant={"secondary"} iconSpacing={3} rightIcon={<FontAwesomeIcon icon={isGroupHidden ? faEyeSlash : faEye} />} onClick={toggleHiddenGroup}>
                {isGroupHidden ? "Hidden" : "Hide"}
              </Button>
              <Button backgroundColor={isGroupFavorite ? yellow : null} colorScheme="brand" variant={"secondary"} iconSpacing={3} rightIcon={<FontAwesomeIcon icon={isGroupFavorite ? faStarSolid : faStar} />} color={isGroupFavorite ? white : null} onClick={toggleFavoriteGroup}>
                {isGroupFavorite ? "Favorited" : "Favorite"}
              </Button>
            </HStack>
          </HStack>
        </VStack>
        <CardDivider />
        <CardWithHeaderElement
          title={"Schedule"}
          headerChildren={
            currentUser.isAdmin && (
              <Tooltip isDisabled={Status === GROUPSTATUS.IDLE && !isEditingNumbers} label={`Unavailable while ${isEditingNumbers ? "editing numbers" : "a job is running"}`}>
                <Button colorScheme="brand" variant={"secondary"} iconSpacing={3} rightIcon={<FontAwesomeIcon icon={faCalendarClock} />} isDisabled={Status !== GROUPSTATUS.IDLE || isEditingNumbers || isLoadingSnapshot} onClick={Status === GROUPSTATUS.IDLE ? editConfigDisclosure.onOpen : null}>
                  Edit Schedule
                </Button>
              </Tooltip>
            )
          }>
          <HStack width={"100%"} justifyContent={"space-evenly"}>
            <HorizontalIconCard title={"Countdown"} icon={Status !== GROUPSTATUS.IDLE ? faGear : faTimer} spinning={Status !== GROUPSTATUS.IDLE}>
              <Skeleton isLoaded={!isLoadingSnapshot}>
                <Counter config={config} Schedule={Schedule} Status={Status} />
              </Skeleton>
            </HorizontalIconCard>

            <HorizontalIconCard title={"Upcoming"} icon={faClockFour}>
              <Skeleton isLoaded={!isLoadingSnapshot}>
                <HStack alignItems={"flex-end"}>
                  <Text fontSize={"2xl"} as={"b"}>
                    {getNextJobFormatted(Schedule).time}
                  </Text>
                  <Text fontSize={"mg"} paddingBottom={1} color={accentgrey}>
                    ({getNextJobFormatted(Schedule).day})
                  </Text>
                </HStack>
              </Skeleton>
            </HorizontalIconCard>

            <HorizontalIconCard title={"Jobs"} icon={faTruckRampBox}>
              <Skeleton isLoaded={!isLoadingSnapshot}>
                <HStack alignItems={"flex-end"}>
                  <Text fontSize={"2xl"} as={"b"}>
                    {getJobsForDay(Schedule, Object.keys(NUMBERED_WEEKDAYS)[dayjs().isoWeekday() - 1])}
                  </Text>
                  <Text fontSize={"mg"} paddingBottom={1} color={accentgrey}>
                    (Today)
                  </Text>
                </HStack>
              </Skeleton>
            </HorizontalIconCard>
          </HStack>

          <HStack justifyContent={"space-around"} width={"100%"}>
            {WEEKDAYS.map((day) => (
              <HStack key={day}>
                {getWeekdayIcon(day)}
                <Heading as={"h4"} size={"sm"}>
                  {day}
                </Heading>
              </HStack>
            ))}
          </HStack>
        </CardWithHeaderElement>
        <CardDivider />
        {numbers.length > 0 && numbers.some((x) => (x?.Status ? Object.values(x.Status) : []).some((status) => status === STATUS.UNKNOWN.VALUE)) && (
          <HStack spacing={4} width={"100%"} backgroundColor={"#fcf8f0"} borderColor={STATUS.UNKNOWN.COLOR} borderWidth={0} borderLeftWidth={4} borderRadius={8} padding={4}>
            <Box>
              <FontAwesomeIcon icon={faCloudQuestion} size="2x" color={STATUS.UNKNOWN.COLOR} />
            </Box>

            <VStack alignItems={"start"} spacing={0}>
              <Heading as={"h4"} size={"sm"}>
                Warning
              </Heading>
              <p>One or more API services are currently unreachable. Please wait for the next queue cycle to try again.</p>
            </VStack>
          </HStack>
        )}
        <NumbersList {...props} Status={Status} isEditingNumbers={isEditingNumbers} setIsEditingNumbers={setIsEditingNumbers} groupId={id} numbers={numbers} organizationServices={organizationServices} isLoadingSnapshot={isLoadingSnapshot} />
      </VStack>
    </>
  );
}

function NumbersList(props) {
  const [checkedItems, setCheckedItems] = useState({});
  const [allChecked, setAllChecked] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const addNumbersDisclosure = useDisclosure();
  const deleteNumbersDisclosure = useDisclosure();

  const { Status, isEditingNumbers, setIsEditingNumbers, numbers, organizationServices, isLoadingSnapshot, groupUsers, currentUser } = props;

  const handleCheck = (number, isChecked) => {
    setCheckedItems((prevState) => ({ ...prevState, [number]: isChecked }));
  };

  const handleCheckAll = (isChecked) => {
    const newCheckedItems = {};
    numbers.forEach((item) => {
      newCheckedItems[item.Number] = isChecked;
    });
    setCheckedItems(newCheckedItems);
    setAllChecked(isChecked);
  };
  const getTableCaption = () => {
    //const historyLastRun = history[0] ?? null;
    const historyLastRun = null;

    if (historyLastRun == null) {
      return "Status of active numbers";
    }

    const lastRunDate = dayjs(historyLastRun.Timestamp.toDate());
    const whichDay = lastRunDate.isToday() ? " (Today)" : lastRunDate.isYesterday() ? " (Yesterday)" : "";

    return lastRunDate.format("DD MMMM YYYY") + whichDay;
  };

  const sortByFailedStatus = (a, b) => {
    const statusOrder = {
      FAILED: 0,
      UNKNOWN: 1,
      SUCCESS: 2,
    };

    // Determine the worst status for each item
    const getWorstStatus = (statusObj) => {
      if (!statusObj) return null;
      return Object.values(statusObj).reduce((worstStatus, currentStatus) => {
        return statusOrder[currentStatus] < statusOrder[worstStatus] ? currentStatus : worstStatus;
      }, "SUCCESS"); // Start with the best status
    };

    const aWorstStatus = getWorstStatus(a.Status);
    const bWorstStatus = getWorstStatus(b.Status);

    // Then sort as before
    if (aWorstStatus === bWorstStatus) {
      return 0;
    }

    if (aWorstStatus === "FAILED") {
      return -1;
    }

    if (bWorstStatus === "FAILED") {
      return 1;
    }

    return statusOrder[aWorstStatus] - statusOrder[bWorstStatus];
  };

  function getOverallStatus(obj) {
    const status = obj.Status;
    if (!status) return null;
    const keys = Object.keys(status);

    if (keys.some((key) => status[key] === STATUS.QUEUE.VALUE)) {
      return STATUS.QUEUE.VALUE;
    } else if (keys.some((key) => status[key] === STATUS.FAILED.VALUE)) {
      return STATUS.FAILED.VALUE;
    } else if (keys.some((key) => status[key] === STATUS.UNKNOWN.VALUE)) {
      return STATUS.UNKNOWN.VALUE;
    } else if (keys.some((key) => status[key] === STATUS.SUCCESS.VALUE)) {
      return STATUS.SUCCESS.VALUE;
    } else {
      return null;
    }
  }

  function getAnyFailedStatus(obj) {
    const status = obj.Status;
    if (!status) return null;
    const keys = Object.keys(status);

    return keys.some((key) => status[key] === STATUS.FAILED.VALUE);
  }

  function getOverallStatusIcon(obj) {
    const status = getOverallStatus(obj);
    if (!status) return null;

    return <FontAwesomeIcon icon={STATUS[status].ICON} color={STATUS[status].COLOR} size="lg" />;
  }

  function getRowBackgroundColor(item, index) {
    const status = getOverallStatus(item);
    const hasAnyFailed = getAnyFailedStatus(item);
    if (hasAnyFailed || status === STATUS.FAILED.VALUE) {
      return "#fcf0f0";
    } else if (status === STATUS.UNKNOWN.VALUE) {
      return "#fcf8f0";
    } else {
      return index % 2 === 0 ? "gray.100" : "white";
    }
  }

  function getNumberTextColor(item) {
    const status = getOverallStatus(item);
    if (status === STATUS.FAILED.VALUE) {
      return danger;
    } else {
      return null;
    }
  }

  const overallNumberStatus = numbers.map((x) => getOverallStatus(x));
  const amountOfJobsPerCategory = {
    SUCCESS: overallNumberStatus.filter((x) => x === STATUS.SUCCESS.VALUE).length,
    FAILED: overallNumberStatus.filter((x) => x === STATUS.FAILED.VALUE).length,
    UNKNOWN: overallNumberStatus.filter((x) => x === STATUS.UNKNOWN.VALUE).length,
    QUEUE: overallNumberStatus.filter((x) => x === STATUS.QUEUE.VALUE).length,
    TOTAL: overallNumberStatus.length,
  };

  useEffect(() => {
    setCanDelete(Object.values(checkedItems).filter((isChecked) => isChecked).length > 0);
  }, [checkedItems]);

  useEffect(() => {
    setCheckedItems({});
    setAllChecked(false);
  }, [isEditingNumbers]);

  const checkedNumbers = Object.keys(checkedItems).filter((number) => checkedItems[number]);
  return (
    <>
      <AddNumbersModal {...addNumbersDisclosure} {...props} />
      <DeleteNumbersModal {...deleteNumbersDisclosure} {...props} numbers={checkedNumbers} setIsEditingNumbers={setIsEditingNumbers} />
      <CardWithSubHeaderElement
        title={"Numbers"}
        subTitleChildren={<JobsPerCategory amountOfJobsPerCategory={amountOfJobsPerCategory} />}
        headerChildren={
          currentUser.isAdmin && (
            <HStack gap={2}>
              {isEditingNumbers ? (
                <Tooltip isDisabled={!checkedNumbers.length === 0} label={"No number selected"}>
                  <Button variant={"danger"} iconSpacing={3} leftIcon={<FontAwesomeIcon icon={faTrash} />} isDisabled={Status !== GROUPSTATUS.IDLE || !canDelete || isLoadingSnapshot} onClick={Status === GROUPSTATUS.IDLE ? deleteNumbersDisclosure.onOpen : null}>
                    {checkedNumbers.length > 1 ? `Delete ${checkedNumbers.length} numbers` : "Delete number"}
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip isDisabled={Status === GROUPSTATUS.IDLE} label={"Unavailable while a job is running"}>
                  <Button colorScheme="brand" iconSpacing={3} leftIcon={<FontAwesomeIcon icon={faPhonePlus} />} isDisabled={Status !== GROUPSTATUS.IDLE || isLoadingSnapshot} onClick={Status === GROUPSTATUS.IDLE ? addNumbersDisclosure.onOpen : null}>
                    Add numbers
                  </Button>
                </Tooltip>
              )}
              <Tooltip isDisabled={Status === GROUPSTATUS.IDLE} label={"Unavailable while a job is running"}>
                <Button colorScheme="brand" variant={"secondary"} iconSpacing={3} rightIcon={<FontAwesomeIcon icon={isEditingNumbers ? faXmark : faBars} />} isDisabled={Status !== GROUPSTATUS.IDLE || isLoadingSnapshot} onClick={() => setIsEditingNumbers((isEditing) => !isEditing)}>
                  {isEditingNumbers ? "Cancel" : "Edit numbers"}
                </Button>
              </Tooltip>
            </HStack>
          )
        }>
        {numbers.length === 0 || isLoadingSnapshot ? (
          <Flex borderColor="gray.300" borderStyle="dashed" borderWidth="2px" rounded="md" height={150} width={"100%"} justifyContent={"center"} alignItems={"center"}>
            <VStack gap={2}>
              {isLoadingSnapshot ? (
                <Spinner size={"lg"} />
              ) : (
                <Text as={"b"}>
                  <FontAwesomeIcon icon={faAdd} size="lg" style={{ marginRight: 2 }} /> Add a number to get started
                </Text>
              )}
            </VStack>
          </Flex>
        ) : (
          <TableContainer borderWidth={2} borderRadius={8} width={"100%"} backgroundColor={white} marginBottom={4}>
            <Table>
              <TableCaption marginTop={0}>{getTableCaption()}</TableCaption>
              <Thead>
                <Tr>
                  <Th>Number</Th>
                  <Th>Created by</Th>
                  {organizationServices.map((service) => (
                    <Th key={service.ID} width={40}>
                      <Tooltip label={service.Value}>
                        <Center>{service.SVG}</Center>
                      </Tooltip>
                    </Th>
                  ))}
                  <Th textAlign={"center"}>Is Verisure</Th>
                  <Th textAlign={"center"}>Searches</Th>
                  <Th textAlign={"center"}>{isEditingNumbers ? <Checkbox isChecked={allChecked} onChange={(e) => handleCheckAll(e.target.checked)} size={"lg"} variant="danger" /> : "Summary"}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {(Status !== GROUPSTATUS.IDLE ? numbers : numbers.sort(sortByFailedStatus)).map((item, index) => (
                  <Tr key={item.Number} backgroundColor={getRowBackgroundColor(item, index)}>
                    <Td paddingY={2}>
                      <Text fontSize={"md"} as={"b"} color={getNumberTextColor(item)}>
                        {item.Number}
                      </Text>
                      <Text fontSize={"sm"}>{item.CheckedAt ? dayjs(item.CheckedAt.toDate()).format("HH:mm:ss") : "Waiting"}</Text>
                    </Td>
                    <Td paddingY={2}>
                      <Flex direction={"column"}>
                        <Text fontSize={"sm"}>{groupUsers.find((x) => x.Email === item.CreatedBy)?.Name}</Text>
                        <Text fontSize={"sm"} as={"b"}>
                          {item.CreatedBy}
                        </Text>
                      </Flex>
                    </Td>
                    {organizationServices.map((service) => (
                      <Td key={service.ID}>
                        <Center>{StatusIcon(item?.Status?.[service.ID])}</Center>
                      </Td>
                    ))}
                    <Td textAlign={"center"}>
                      <Tooltip label={item?.IsVerisure ? "This number is associated with Verisure" : "This number is NOT associated with Verisure"}>
                        <FontAwesomeIcon icon={faFlag} color={item?.IsVerisure ? COLORS.IS_VERISURE : null} size="lg" />
                      </Tooltip>
                    </Td>
                    <Td textAlign={"center"}>
                      <Tooltip label={item?.Searches ? (item.Searches >= 25 ? "This number has been searched many times" : "This number has been searched few times") : "This number has not been checked yet"}>
                        <FontAwesomeIcon icon={faFlag} color={item?.Searches ? (item.Searches >= 25 ? STATUS.FAILED.COLOR : STATUS.SUCCESS.COLOR) : null} size="lg" />
                      </Tooltip>
                    </Td>
                    <Td textAlign={"center"}>{isEditingNumbers ? <Checkbox isChecked={checkedItems[item.Number] || false} onChange={(e) => handleCheck(item.Number, e.target.checked)} size={"lg"} variant="danger" /> : getOverallStatusIcon(item)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </CardWithSubHeaderElement>
    </>
  );
}

function StatusIcon(status) {
  if (!status) {
    return <FontAwesomeIcon icon={faClock} color={STATUS.WAITING.COLOR} aria-hidden="true" size="lg" />;
  }

  return (
    <Tooltip label={STATUS[status]?.TOOLTIP}>
      {(() => {
        if (status === STATUS.SUCCESS.VALUE) {
          return <FontAwesomeIcon icon={faCircleCheck} color={STATUS.SUCCESS.COLOR} aria-hidden="true" size="lg" />;
        } else if (status === STATUS.FAILED.VALUE) {
          return <FontAwesomeIcon icon={faCircleXmark} color={STATUS.FAILED.COLOR} aria-hidden="true" size="lg" />;
        } else if (status === STATUS.UNKNOWN.VALUE) {
          return <FontAwesomeIcon icon={faCircleQuestion} color={STATUS.UNKNOWN.COLOR} aria-hidden="true" size="lg" />;
        } else if (status === STATUS.WAITING.VALUE) {
          return <FontAwesomeIcon icon={faClock} color={STATUS.WAITING.COLOR} aria-hidden="true" size="lg" />;
        } else {
          return <Spinner label="Loading" />;
        }
      })()}
    </Tooltip>
  );
}

function JobsPerCategory({ amountOfJobsPerCategory }) {
  const statuses = [
    { icon: null, color: grey, label: "Total", category: "TOTAL" },
    { icon: faCheck, color: STATUS.SUCCESS.COLOR, label: "Success", category: "SUCCESS" },
    { icon: faXmarkSolid, color: STATUS.FAILED.COLOR, label: "Failure", category: "FAILED" },
    { icon: faQuestion, color: STATUS.UNKNOWN.COLOR, label: "Unknown", category: "UNKNOWN" },
    { icon: faClockFour, color: STATUS.QUEUE.COLOR, label: "Queue", category: "QUEUE" },
  ];

  return (
    <HStack justifyContent={"space-evenly"} gap={2}>
      {statuses.map(({ icon, color, label, category }) => (
        <HStack key={category} borderRadius={10} opacity={amountOfJobsPerCategory[category] > 0 ? 1 : 0.5}>
          <HStack>
            {icon && <FontAwesomeIcon icon={icon} color={color} />}
            <Text>
              <Text as="span" fontWeight={amountOfJobsPerCategory[category] > 0 ? "bold" : "normal"}>
                {amountOfJobsPerCategory[category]}
              </Text>{" "}
              {label}
            </Text>
          </HStack>
        </HStack>
      ))}
    </HStack>
  );
}
