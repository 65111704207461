import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Checkbox, CheckboxGroup, Grid, HStack, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useBoolean, useToast } from "@chakra-ui/react";
import { LogActivityGroup } from "api/firebase";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { memo, useEffect, useMemo, useState } from "react";
import { ACTIVITY, COLLECTION, HOURS, WEEKDAYS } from "../../../data/enum";
import { BrandDivider } from "../../Miscellaneous/Brand";

export default function EditScheduleModal({ Status, isOpen, onOpen, onClose, config, Schedule, groupId, currentUser }) {
  const [isLoading, setLoading] = useBoolean();
  const [schedules, setSchedules] = useState(Schedule);

  const toast = useToast();

  const updateSchedule = () => {
    setLoading.on();
    const db = getFirestore();

    const groupRef = doc(db, COLLECTION.ORGANIZATIONS, config.ID, COLLECTION.GROUPS, groupId);

    // Filter out days with empty schedules
    const filteredSchedules = Object.fromEntries(Object.entries(schedules).filter(([, hours]) => hours.length > 0));

    // Update the group document's Schedule field with the new schedule
    updateDoc(groupRef, { Schedule: filteredSchedules })
      .then(() => {
        toast({
          title: `Updated schedule`,
          status: "success",
          duration: 2500,
          isClosable: false,
        });

        LogActivityGroup(config.ID, groupId, ACTIVITY.ACTION.UPDATED, ACTIVITY.TYPE.SCHEDULE, filteredSchedules, currentUser);
      })
      .finally(() => {
        setLoading.off();
        onClose();
      });
  };

  const onClear = () => {
    setSchedules({});
  };

  useEffect(() => {
    onClose();
    // eslint-disable-next-line
  }, [Status]);

  useEffect(() => {
    if (isOpen) {
      setSchedules(Schedule);
    } else {
      setSchedules({});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered closeOnEsc={!isLoading} closeOnOverlayClick={!isLoading}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={0}>Edit schedule</ModalHeader>
        <ModalCloseButton isDisabled={isLoading} />
        <ModalBody mb={4}>
          <Text marginBottom={4}>Manage schedules for this group.</Text>
          <BrandDivider />
          <Accordion allowToggle gap={2}>
            {WEEKDAYS.map((dayOfWeek) => (
              <ScheduleDay key={dayOfWeek} day={dayOfWeek} schedules={schedules} setSchedules={setSchedules} />
            ))}
          </Accordion>
        </ModalBody>
        <ModalFooter gap={4}>
          <HStack justifyContent={"space-between"} width={"100%"}>
            <Button onClick={onClear} isDisabled={isLoading} variant={"secondary"}>
              Clear
            </Button>

            <Box>
              <Button onClick={onClose} isDisabled={isLoading} variant={"secondary"} marginEnd={4}>
                Cancel
              </Button>
              <Button onClick={updateSchedule} isLoading={isLoading} colorScheme="brand">
                Update schedule
              </Button>
            </Box>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function ScheduleDay({ day, schedules, setSchedules }) {
  const selectedHours = schedules[day] || [];

  const setSelectedHours = (newHours) => {
    setSchedules((prevSchedules) => ({ ...prevSchedules, [day]: newHours }));
  };

  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex="1" textAlign="left" opacity={selectedHours.length > 0 ? 1 : 0.5}>
            <Heading as={"h3"} size={"sm"}>
              {day}
            </Heading>
            <Text as={selectedHours.length > 0 ? "p" : "i"}>{selectedHours.length > 0 ? selectedHours.sort().join(" | ") : "None"}</Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <TimePicker enabled={true} selectedHours={selectedHours} setSelectedHours={setSelectedHours} />
      </AccordionPanel>
    </AccordionItem>
  );
}

function TimePicker({ selectedHours, setSelectedHours, enabled }) {
  const handleTimeChange = (time) => {
    const selectedHoursSet = new Set(selectedHours);
    if (selectedHoursSet.has(time)) {
      selectedHoursSet.delete(time);
      setSelectedHours(Array.from(selectedHoursSet));
    } else {
      setSelectedHours([...selectedHours, time]);
    }
  };

  // Convert selectedHours to a Set for O(1) lookups
  const selectedHoursSet = useMemo(() => new Set(selectedHours), [selectedHours]);

  const renderTimeCheckboxes = useMemo(
    () => HOURS.map((time) => <CheckboxTimeItem key={time} time={time} isChecked={selectedHoursSet.has(time)} disabled={!enabled} handleTimeChange={() => handleTimeChange(time)} />),
    // eslint-disable-next-line
    [selectedHoursSet, enabled]
  );

  return (
    <Grid templateColumns="repeat(4, 4fr)" gap={2}>
      <CheckboxGroup key={"CheckboxGroupTime"} colorScheme="brand">
        {renderTimeCheckboxes}
      </CheckboxGroup>
    </Grid>
  );
}

const CheckboxTimeItem = memo(({ time, isChecked, disabled, handleTimeChange }) => (
  <Checkbox key={time} size={"lg"} isChecked={isChecked} disabled={disabled} onChange={handleTimeChange}>
    {time}
  </Checkbox>
));
