import { Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useBoolean, useToast } from "@chakra-ui/react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useEffect, useState } from "react";

export default function ForgotPasswordModal({ isOpen, onOpen, onClose }) {
  const [isLoading, setLoading] = useBoolean();
  const [email, setEmail] = useState("");

  const toast = useToast();

  const getEmail = () => email.replace(" ", "");
  const isEmailValid = (input) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(input);
  };

  function resetPassword() {
    setLoading.on();

    const userEmail = getEmail();

    if (isEmailValid(userEmail)) {
      const auth = getAuth();
      sendPasswordResetEmail(auth, userEmail)
        .then(() => {
          onClose();

          toast({
            title: "Email has been sent!",
            status: "success",
            duration: 2500,
            isClosable: false,
          });
        })
        .catch((error) => {
          console.error(error);
          toast({
            title: "Something went wrong.",
            status: "error",
            duration: 2500,
            isClosable: false,
          });
        })
        .finally(setLoading.off);
    } else {
      //TODO: Show error alert
      setLoading.off();
    }
  }

  useEffect(() => {
    setEmail("");
  }, [isOpen]);

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      resetPassword();
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered closeOnEsc={!isLoading} closeOnOverlayClick={!isLoading}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={0}>Forgot password?</ModalHeader>
        <ModalCloseButton isDisabled={isLoading} />
        <ModalBody mb={4}>
          <Text marginBottom={4}>Enter your email and we'll send you a link to get back into your account.</Text>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            isDisabled={isLoading}
            placeholder="example@email.com"
            size="md"
            type="email"
            isInvalid={!isEmailValid()}
            onKeyDownCapture={onKeyPress}
            autoFocus
          />
        </ModalBody>
        <ModalFooter gap={4}>
          <Button onClick={onClose} isDisabled={isLoading} variant={"secondary"}>
            Cancel
          </Button>
          <Button onClick={resetPassword} isLoading={isLoading} colorScheme="brand">
            Reset password
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
