export function calculateHittaCost(numberOfRequests) {
  let costPerSearch = 0.42;

  return Math.ceil(costPerSearch * numberOfRequests);
}

export function calculateEniroCost(numberOfRequests) {
  let cost;

  if (numberOfRequests === 0) {
    cost = 0;
  } else if (numberOfRequests < 5000) {
    cost = 990;
  } else if (numberOfRequests < 50000) {
    cost = 2950;
  } else if (numberOfRequests < 250000) {
    cost = 7990;
  } else if (numberOfRequests < 1000000) {
    cost = 20000;
  } else {
    cost = 35000;
  }

  return cost;
}

export function adjustCostsPreservingTotal(eniroCost, hittaCost) {
  const totalOriginalCost = eniroCost + hittaCost;

  let adjustedHittaCost = totalOriginalCost / 1.8;

  let adjustedEniroCost = 0.8 * adjustedHittaCost;

  return [Math.ceil(adjustedEniroCost), Math.ceil(adjustedHittaCost)];
}

export function roundDown(number) {
  const roundedToTen = Math.floor(number / 10) * 10;
  const roundedToHundred = Math.floor(number / 100) * 100;
  const roundedToThousand = Math.floor(number / 1000) * 1000;

  // Return the highest non-zero rounded value
  if (roundedToThousand > 0) {
    return roundedToThousand;
  } else if (roundedToHundred > 0) {
    return roundedToHundred;
  } else {
    return roundedToTen;
  }
}
