const KameleontSVG = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" id="Layer_1" viewBox="0 0 1000 1000" {...props}>
    <style>{".st0,.st1{stroke:#000;stroke-width:50;stroke-miterlimit:10}.st1{fill:none;stroke:#20ba59;stroke-width:70}"}</style>
    <path d="M832.5 535.2h63v30.7h-63z" className="st0" />
    <path d="M516.5 545H256.2c-52 0-94.2-41.2-94.2-92.2C162 345.7 250.7 259 360 259h156.5v133" className="st1" />
    <path d="M541.7 357.1c117.8 1.3 215.9 46.2 232.8 160.3 17 115-8 204-129 221-118.2 16.6-193.6-47.9-220-160.2" className="st1" />
    <circle
      cx={290.8}
      cy={427.8}
      r={40.9}
      style={{
        fill: "#20ba59",
      }}
    />
    <path d="M104.5 528.5h521.4c0 20.6-13.7 37.4-30.7 37.4H104.5v-37.4z" className="st0" />
  </svg>
);

export default KameleontSVG;
