import { Button, FormControl, FormLabel, Input, InputGroup, InputLeftAddon, InputRightAddon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useBoolean, useToast } from "@chakra-ui/react";
import { faKey, faUser } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EniroSVG from "components/Logo/Eniro";
import HittaSVG from "components/Logo/Hitta";
import { BrandDivider } from "components/Miscellaneous/Brand";
import { doc, getDoc, getFirestore, writeBatch } from "firebase/firestore";
import { useEffect, useState } from "react";
import { COLLECTION, DOC } from "../../../data/enum";

export default function ConfigurationModal({ isOpen, onOpen, onClose }) {
  const [isLoading, setLoading] = useBoolean();
  const [eniroUsername, setEniroUsername] = useState("");
  const [eniroKey, setEniroKey] = useState("");
  const [hittaUsername, setHittaUsername] = useState("");
  const [hittaKey, setHittaKey] = useState("");

  const toast = useToast();

  const handleInputChangeEniroUsername = (event) => {
    setEniroUsername(event.target.value);
  };

  const handleInputChangeEniroKey = (event) => {
    setEniroKey(event.target.value);
  };

  const handleInputChangeHittaUsername = (event) => {
    setHittaUsername(event.target.value);
  };

  const handleInputChangeHittaKey = (event) => {
    setHittaKey(event.target.value);
  };

  function updateKeys() {
    setLoading.on();

    const db = getFirestore();

    const batch = writeBatch(db);

    batch.update(doc(db, COLLECTION.CONFIGURATION, DOC.ENIRO), {
      Username: eniroUsername.trim(),
      Key: eniroKey.trim(),
    });

    batch.update(doc(db, COLLECTION.CONFIGURATION, DOC.HITTA), {
      Username: hittaUsername.trim(),
      Key: hittaKey.trim(),
    });

    batch
      .commit()
      .then(() => {
        onClose();

        toast({
          title: `Updated API keys`,
          status: "success",
          duration: 2500,
          isClosable: false,
        });
      })
      .catch((e) => {
        console.error(e);

        toast({
          title: "Failed to update API keys",
          status: "error",
          duration: 2500,
          isClosable: false,
        });
      })
      .finally(setLoading.off);
  }

  function getKeys() {
    setLoading.on();

    const db = getFirestore();

    Promise.all([getDoc(doc(db, COLLECTION.CONFIGURATION, DOC.ENIRO)), getDoc(doc(db, COLLECTION.CONFIGURATION, DOC.HITTA))])
      .then(([eniroApi, hittaApi]) => {
        const { Username: eniroUsername, Key: eniroKey } = eniroApi.data();
        const { Username: hittaUsername, Key: hittaKey } = hittaApi.data();

        setEniroUsername(eniroUsername);
        setEniroKey(eniroKey);

        setHittaUsername(hittaUsername);
        setHittaKey(hittaKey);
      })
      .finally(setLoading.off);
  }

  useEffect(() => {
    if (isOpen) {
      getKeys();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered closeOnEsc={!isLoading} closeOnOverlayClick={!isLoading}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={0}>Configuration</ModalHeader>
        <ModalCloseButton isDisabled={isLoading} />
        <ModalBody mb={4}>
          <Text>Manage API keys for services.</Text>
          <BrandDivider />
          <FormControl marginY={4}>
            <FormLabel>Eniro</FormLabel>
            <InputGroup>
              <InputLeftAddon>
                <FontAwesomeIcon icon={faUser} />
              </InputLeftAddon>
              <Input type="text" value={eniroUsername} onChange={handleInputChangeEniroUsername} isDisabled={isLoading} placeholder="api@athlas.io" size="md" autoFocus />
              <InputRightAddon>
                <EniroSVG width="20px" />
              </InputRightAddon>
            </InputGroup>
          </FormControl>
          <FormControl marginY={4}>
            <InputGroup>
              <InputLeftAddon>
                <FontAwesomeIcon icon={faKey} />
              </InputLeftAddon>
              <Input type="text" value={eniroKey} onChange={handleInputChangeEniroKey} isDisabled={isLoading} placeholder="cwWG0JMR7xFhZdakFfBbwuNnl01LcnjflaF" size="md" autoFocus />
              <InputRightAddon>
                <EniroSVG width="20px" />
              </InputRightAddon>
            </InputGroup>
          </FormControl>
          <FormControl marginY={4}>
            <FormLabel>Hitta</FormLabel>
            <InputGroup>
              <InputLeftAddon>
                <FontAwesomeIcon icon={faUser} />
              </InputLeftAddon>
              <Input type="text" value={hittaUsername} onChange={handleInputChangeHittaUsername} isDisabled={isLoading} placeholder="AthlasIO" size="md" autoFocus />
              <InputRightAddon>
                <HittaSVG width="20px" />
              </InputRightAddon>
            </InputGroup>
          </FormControl>
          <FormControl marginY={4}>
            <InputGroup>
              <InputLeftAddon>
                <FontAwesomeIcon icon={faKey} />
              </InputLeftAddon>
              <Input type="text" value={hittaKey} onChange={handleInputChangeHittaKey} isDisabled={isLoading} placeholder="U8B3MOYPymI8SQlvHnuEIC5LE0mbwzPl" size="md" autoFocus />
              <InputRightAddon>
                <HittaSVG width="20px" />
              </InputRightAddon>
            </InputGroup>
          </FormControl>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button onClick={onClose} isDisabled={isLoading} variant={"secondary"}>
            Cancel
          </Button>
          <Button onClick={updateKeys} isLoading={isLoading} colorScheme="brand">
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
