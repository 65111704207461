const HundraSVG = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 978.59 986.51" {...props}>
    <rect
      width={239.75}
      height={159.69}
      x={6.64}
      y={805.42}
      fill="#4caa54"
      stroke="#231f20"
      strokeMiterlimit={10}
      rx={79.84}
      transform="rotate(-45 108.639 903.13)"
    />
    <path
      fill="#4caa54"
      stroke="#4baa54"
      strokeMiterlimit={10}
      strokeWidth={31}
      d="M525.9 15.5c-241.45 0-437.19 195.73-437.19 437.19S284.44 889.88 525.9 889.88s437.24-195.73 437.24-437.19S767.35 15.5 525.9 15.5Zm0 786.78c-193.08 0-349.6-156.52-349.6-349.6s156.52-349.59 349.6-349.59 349.6 156.5 349.6 349.6-156.52 349.6-349.6 349.6Z"
    />
    <g
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={8}
      style={{
        isolation: "isolate",
      }}
    >
      <path d="M403.81 452.97q0 71.07-23.29 105.2t-71.71 34.14q-46.95 0-70.79-35.25t-23.88-104.09q0-71.81 23.19-105.86t71.44-34q46.93 0 71 35.62t24.04 104.24Zm-132.67 0q0 49.9 8.63 71.53t29 21.61q20 0 28.95-21.89t8.9-71.25q0-49.92-9-71.72t-28.85-21.8q-20.22 0-28.95 21.8t-8.68 71.72ZM459.47 588.59l101.87-222.64H427.56V317.7H622.2v36L519.59 588.59ZM837.62 452.97q0 71.07-23.29 105.2t-71.71 34.14q-47 0-70.79-35.25t-23.84-104.09q0-71.81 23.19-105.86t71.44-34q46.93 0 71 35.62t24 104.24Zm-132.67 0q0 49.9 8.63 71.53t29 21.61q20 0 28.94-21.89t8.91-71.25q0-49.92-9-71.72t-28.85-21.8q-20.24 0-29 21.8t-8.63 71.72Z" />
    </g>
  </svg>
);

export default HundraSVG;
