import { Flex, ScaleFade, Text } from "@chakra-ui/react";
import { faFolderMagnifyingGlass } from "@fortawesome/pro-duotone-svg-icons";
import { VerticalIconCard } from "components/Miscellaneous/Cards";

export default function NoGroups() {
  return (
    <Flex minH={"100vh"} width={"100%"} alignItems={"center"} justifyContent={"center"}>
      <ScaleFade initialScale={0.9} in={true}>
        <VerticalIconCard title={"No groups found"} icon={faFolderMagnifyingGlass}>
          <Text fontSize={"lg"}>Create a group to get started</Text>
        </VerticalIconCard>
      </ScaleFade>
    </Flex>
  );
}
