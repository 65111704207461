export function formatActivity(type, data) {
  if (Array.isArray(data)) {
    return data.join(", ");
  } else if (type === "FILES") {
    return data?.Filename;
  } else if (type === "GROUP") {
    return data?.Title;
  } else if (type === "USER") {
    return data?.Email;
  }

  return JSON.stringify(data);
}
