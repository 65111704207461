import { Flex, Heading, Stack, Text } from "@chakra-ui/react";

export default function Error() {
  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"6xl"}>404</Heading>
          <Text fontSize={"lg"} color={"gray.600"} textAlign={"center"}>
            Something went wrong. The hamsters running our server seem to be taking a break. Please try again later.
          </Text>
        </Stack>
      </Stack>
    </Flex>
  );
}
