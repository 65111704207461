import { Container } from "@chakra-ui/react";
import { numberGroupSort } from "api/sort";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import isYesterday from "dayjs/plugin/isYesterday";
import isoWeek from "dayjs/plugin/isoWeek";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, collectionGroup, doc, getDoc, getDocs, getFirestore, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IS_DEVELOPMENT } from "variables";
import { initializeFirebase } from "../api/firebase";
import { COLLECTION, HIDDEN_ROLES, ROLES } from "../data/enum";
import Login from "./Home/Login";
import NoOrganization from "./Home/NoOrganization";
import Overview from "./Home/Overview";
import PickOrganization from "./Home/PickOrganization";
import ResetPassword from "./Home/ResetPassword";
import Loading from "./Miscellaneous/Loading";

export default function Router() {
  const [userDoc, setUserDoc] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(null);
  const [config, setConfig] = useState(null);
  const [numberGroups, setNumberGroups] = useState(null);
  const [groupUsers, setGroupUsers] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [showOrganizationPicker, setShowOrganizationPicker] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const isWelcome = location.pathname === "/";
  const isLogin = location.pathname === "/login";
  const isReset = location.pathname === "/reset";

  useLayoutEffect(() => {
    initialize();
    initializeFirebase();

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (IS_DEVELOPMENT) console.log("Auth change", user, isSignedIn);
      setIsSignedIn(user ? true : false);

      if (location.pathname !== "/" && user) {
        navigate("/");
      }

      if (user) {
        getConfig(user.email);
      } else {
        setCurrentUser(null);
        setGroupUsers(null);
        setNumberGroups(null);
        setConfig(null);
        setUserDoc(null);
        setOrganizations(null);
        setShowOrganizationPicker(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userDoc) {
      const subscription = onSnapshot(userDoc.ref, (doc) => {
        if (IS_DEVELOPMENT) console.log("Organization listener", doc.data());
        setConfig((prevConfig) => {
          return {
            ID: doc.id,
            ...prevConfig,
            ...doc.data(),
          };
        });
      });

      return subscription;
    }
  }, [userDoc]);

  useEffect(() => {
    if (userDoc) {
      const db = getFirestore();
      const auth = getAuth();

      const userOrganizationsRef = collectionGroup(db, COLLECTION.MEMBEROF);
      const organizationsQuery = query(userOrganizationsRef, where("OrganizationId", "==", userDoc.id));
      const subscription = onSnapshot(organizationsQuery, (organizations) => {
        const organizationWithUser = organizations.docs.map((doc) => ({ _ref: doc.ref, ...doc.data() }));

        // Set current user and assign variables that will help with permission checking
        let user = organizationWithUser.find((user) => user.Email === auth.currentUser.email);
        user.isOwner = user.Role === HIDDEN_ROLES.SUPPORT || user.Role === ROLES.OWNER;
        user.isAdmin = user.Role === HIDDEN_ROLES.SUPPORT || user.Role === ROLES.OWNER || user.Role === ROLES.ADMIN;
        user.isSupport = user.Role === HIDDEN_ROLES.SUPPORT;

        if (IS_DEVELOPMENT) console.log("Users listener", organizationWithUser, "Current user", user);
        setCurrentUser(user);
        setGroupUsers(organizationWithUser);
      });

      return subscription;
    }
  }, [userDoc]);

  useEffect(() => {
    if (userDoc) {
      const db = getFirestore();
      const numbersCollection = collection(db, COLLECTION.ORGANIZATIONS, userDoc.id, COLLECTION.GROUPS);
      const subscription = onSnapshot(numbersCollection, (groupListSnapshot) => {
        const promises = groupListSnapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        });

        Promise.all(promises)
          .then((groupList) => {
            if (IS_DEVELOPMENT) console.log("Number group listener", groupList);
            setNumberGroups(numberGroupSort(groupList));
          })
          .catch((error) => {
            console.error("Error fetching subcollections: ", error);
          });
      });

      return subscription;
    }
  }, [userDoc]);

  useEffect(() => {
    if (userDoc) {
      setShowOrganizationPicker(false);
    }
  }, [userDoc]);

  useEffect(() => {
    if (showOrganizationPicker) {
      setCurrentUser(null);
      setGroupUsers(null);
      setNumberGroups(null);
      setConfig(null);
      setUserDoc(null);
    }
  }, [showOrganizationPicker]);

  function initialize() {
    const plugins = [customParseFormat, duration, timezone, isToday, isYesterday, isTomorrow, utc, weekday, isoWeek, advancedFormat, isoWeek];
    plugins.forEach((plugin) => dayjs.extend(plugin));
    dayjs.tz.setDefault("Europe/Stockholm");
  }

  async function getConfig(email) {
    const db = getFirestore();
    const auth = getAuth();

    const userRef = doc(db, COLLECTION.USERS, email);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      console.error("User does not exist");
      auth.signOut();
      return null;
    }

    const userOrganizationRef = collection(userRef, COLLECTION.MEMBEROF);
    const userOrganizations = await getDocs(userOrganizationRef);

    const organizationIds = userOrganizations.docs.map((x) => x.id);
    const organizationRefs = organizationIds.map((id) => doc(db, COLLECTION.ORGANIZATIONS, id));
    const organizationsPromises = organizationRefs.map((ref) => getDoc(ref));
    const organizationsSnapshots = await Promise.all(organizationsPromises);
    const availableOrganizations = organizationsSnapshots.filter((docSnapshot) => docSnapshot.exists());

    setOrganizations(availableOrganizations);
    if (availableOrganizations.length > 1) {
      setShowOrganizationPicker(true);
    } else if (availableOrganizations.length === 1) {
      setUserDoc(availableOrganizations[0]);
    }
  }

  if (organizations && organizations.length === 0) {
    return <NoOrganization />;
  }

  if (organizations && showOrganizationPicker) {
    const user = getAuth().currentUser;
    return <PickOrganization user={user} organizations={organizations} setOrganization={setUserDoc} />;
  }

  if (isSignedIn === null || (isSignedIn === true && (config === null || numberGroups === null || groupUsers === null || currentUser === null))) {
    return <Loading />;
  } else if (isSignedIn && config !== null && numberGroups !== null && groupUsers !== null && currentUser !== null) {
    return (
      <Container width={"max"} margin={0} padding={0}>
        <Overview config={config} numberGroups={numberGroups} groupUsers={groupUsers} currentUser={currentUser} organizations={organizations} showOrganizationPicker={setShowOrganizationPicker} />
      </Container>
    );
  } else if (isLogin || isReset) {
    return <Container width={"max"}>{isLogin ? <Login /> : <ResetPassword />}</Container>;
  } else {
    if (IS_DEVELOPMENT && isWelcome) {
      navigate("/login");
    } else {
      window.location.href = "https://welcome.kameleont.io";
    }

    return <Loading />;
  }
}
