import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { adjustCostsPreservingTotal, calculateEniroCost, calculateHittaCost } from "api/cost";
import { grey, white } from "colors";
import { BrandDivider } from "components/Miscellaneous/Brand";
import dayjs from "dayjs";

export default function InvoiceModal({ isOpen, onOpen, onClose, invoice }) {
  if (!invoice) return null;

  const adjustment = invoice?.Adjusted ?? 1;
  const eniroRevenue = calculateEniroCost(invoice.Eniro);
  const hittaRevenue = calculateHittaCost(invoice.Hitta);
  const [distributedEniroRevenue, distributedHittaRevenue] = adjustCostsPreservingTotal(eniroRevenue, hittaRevenue);
  const totalRevenue = eniroRevenue + hittaRevenue;
  const totalMarginRevenue = totalRevenue * 1.1 * adjustment;

  const information = [
    { title: "Eniro", amount: invoice.Eniro, total: distributedEniroRevenue * adjustment },
    { title: "Hitta", amount: invoice.Hitta, total: distributedHittaRevenue * adjustment },
    { title: "10% Margin", amount: null, total: totalRevenue * 0.1 * adjustment },
  ];

  return (
    <Modal size={"sm"} onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={0}>
          {dayjs(invoice.Timestamp.toDate()).subtract(1, "month").format("MMMM")}{" "}
          <Text fontSize={"sm"} color={grey}>
            invoice
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody mb={4}>
          <HStack justifyContent={"space-between"} marginBottom={2}>
            <Text fontWeight={"bold"}>{`#-${invoice.index.toString().padStart(3, "0")}`}</Text>
            <Text fontWeight={"bold"}>{dayjs(invoice.Timestamp.toDate()).format("YYYY-MM-DD")}</Text>
          </HStack>

          <BrandDivider />
          <VStack spacing={2}>
            <TableContainer borderWidth={0} borderRadius={8} width={"100%"} backgroundColor={white} marginBottom={2}>
              <Table variant="striped">
                <Thead>
                  <Tr>
                    <Th>Item</Th>
                    <Th textAlign={"end"}>Price</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {information.map((item, index) => (
                    <Tr key={item.title}>
                      <Td paddingY={2}>
                        <Flex direction={"column"} justifyContent={"center"} alignItems={"flex-start"}>
                          <Text fontSize={"md"} as={"b"}>
                            {item.title}
                          </Text>
                          <Text fontSize={"sm"} color={grey}>
                            {item.amount && `${item.amount} requests`}
                          </Text>
                        </Flex>
                      </Td>
                      <Td paddingY={2}>
                        <Flex direction={"column"} justifyContent={"center"} alignItems={"flex-end"}>
                          <Text fontSize={"md"} as={"b"}>
                            {Math.ceil(item.total).toString().replace(".", ",")}
                          </Text>
                          <Text fontSize={"sm"} color={grey}>
                            SEK
                          </Text>
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
            <BrandDivider />
            <HStack justifyContent={"space-between"} width={"100%"} paddingX={2} alignItems={"flex-start"}>
              <Text fontWeight={"bold"} fontSize={"lg"}>
                Total
              </Text>
              <VStack spacing={0} alignItems={"flex-end"}>
                <Text fontWeight={"bold"} fontSize={"lg"}>
                  {Math.ceil(totalMarginRevenue)}
                </Text>
                <Text fontSize={"sm"} color={grey}>
                  SEK
                </Text>
              </VStack>
            </HStack>
          </VStack>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button onClick={onClose} variant={"secondary"}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
