import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { LogActivityGroup } from "api/firebase";
import { BrandDivider } from "components/Miscellaneous/Brand";
import { getAuth } from "firebase/auth";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { useEffect, useState } from "react";
import { ACTIVITY, COLLECTION } from "../../../data/enum";

export default function AddGroupModal({ isOpen, onOpen, onClose, config, currentUser }) {
  const [isLoading, setLoading] = useBoolean();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleError, setTitleError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);

  const toast = useToast();

  const maxLengthTitle = 15;
  const minLengthTitle = 2;
  const maxLengthDescription = 100;
  const minLengthDescription = 0;

  const handleInputChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleInputChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const validateTitle = () => {
    if (title.trim() === "") {
      setTitleError("Title cannot be empty");
    } else if (title.length > maxLengthTitle) {
      setTitleError(`Title must have maximum ${maxLengthTitle} characters`);
    } else if (title.length < minLengthTitle) {
      setTitleError(`Title must have minimum ${minLengthTitle} characters`);
    } else {
      setTitleError(null);
    }
  };

  const validateDescription = () => {
    if (description.length > maxLengthDescription) {
      setDescriptionError(`Description has to be maximum ${maxLengthDescription} characters`);
    } else if (description.length < minLengthDescription) {
      setTitleError(`Description has to be minimum ${minLengthDescription} characters`);
    } else {
      setDescriptionError(null);
    }
  };

  function addGroup() {
    validateTitle();
    validateDescription();

    if (
      title.length >= minLengthTitle &&
      description.length >= minLengthDescription &&
      title.length <= maxLengthTitle &&
      description.length <= maxLengthDescription
    ) {
      setLoading.on();

      const db = getFirestore();
      const auth = getAuth();

      const currentUserEmail = auth.currentUser.email;

      addDoc(collection(db, COLLECTION.ORGANIZATIONS, config.ID, COLLECTION.GROUPS), {
        Title: title.trim(),
        Description: description.trim(),
        CreatedBy: currentUserEmail,
        Enabled: true,
      })
        .then((doc) => {
          onClose();

          toast({
            title: `Added group`,
            status: "success",
            duration: 2500,
            isClosable: false,
          });

          LogActivityGroup(
            config.ID,
            doc.id,
            ACTIVITY.ACTION.CREATED,
            ACTIVITY.TYPE.GROUP,
            { Id: doc.id, Title: title, Description: description },
            currentUser
          );
        })
        .catch((e) => {
          console.error(e);

          toast({
            title: "Failed to add group",
            status: "error",
            duration: 2500,
            isClosable: false,
          });
        })
        .finally(() => setLoading.off());
    }
  }

  useEffect(() => {
    setTitle("");
    setDescription("");
    setTitleError(null);
    setDescriptionError(null);
  }, [isOpen]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered closeOnEsc={!isLoading} closeOnOverlayClick={!isLoading}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={0}>Create group</ModalHeader>
        <ModalCloseButton isDisabled={isLoading} />
        <ModalBody mb={4}>
          <Text marginBottom={4}>Enter information about your new group.</Text>
          <BrandDivider />
          <FormControl isInvalid={titleError !== null} id="title-input" mt={4} mb={4}>
            <FormLabel>Title</FormLabel>
            <Input type="text" value={title} onChange={handleInputChangeTitle} isDisabled={isLoading} placeholder="Group title" size="md" autoFocus />
            <FormErrorMessage>{titleError}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={descriptionError !== null} id="description-input" mt={4}>
            <FormLabel>Description</FormLabel>
            <Input type="text" value={description} onChange={handleInputChangeDescription} isDisabled={isLoading} placeholder="Group Description" size="md" />
            <FormErrorMessage>{descriptionError}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button onClick={onClose} isDisabled={isLoading} variant={"secondary"}>
            Cancel
          </Button>
          <Button onClick={addGroup} isLoading={isLoading} colorScheme="brand">
            Create group
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
