import { Box, Button, FormControl, FormErrorMessage, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useBoolean, useToast } from "@chakra-ui/react";
import { LogActivityGroup } from "api/firebase";
import { BrandDivider } from "components/Miscellaneous/Brand";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { ACTIVITY, COLLECTION, GROUPSTATUS } from "../../../data/enum";

export default function EditGroupModal({ Status, isOpen, onOpen, onClose, config, groupId, Title, Description, Folder, numbers, currentUser }) {
  const [isLoading, setLoading] = useBoolean();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [folder, setFolder] = useState("");
  const [titleError, setTitleError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [folderError, setFolderError] = useState(null);

  const toast = useToast();

  const maxLengthTitle = 60;
  const minLengthTitle = 2;
  const maxLengthDescription = 200;
  const minLengthDescription = 0;

  const handleInputChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleInputChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleInputChangeFolder = (event) => {
    setFolder(event.target.value);
  };

  const validateTitle = () => {
    if (title.trim() === "") {
      setTitleError("Title cannot be empty");
    } else if (title.length > maxLengthTitle) {
      setTitleError(`Title must have maximum ${maxLengthTitle} characters`);
    } else if (title.length < minLengthTitle) {
      setTitleError(`Title must have minimum ${minLengthTitle} characters`);
    } else {
      setTitleError(null);
    }
  };

  const validateDescription = () => {
    if (description.length > maxLengthDescription) {
      setDescriptionError(`Description has to be maximum ${maxLengthDescription} characters`);
    } else {
      setDescriptionError(null);
    }
  };

  const validateFolder = () => {
    if (folder.length > maxLengthTitle) {
      setFolderError(`Folder has to be maximum ${maxLengthTitle} characters`);
    } else {
      setFolderError(null);
    }
  };

  function updateGroup() {
    validateTitle();
    validateDescription();
    validateFolder();

    if (title.length >= minLengthTitle && description.length >= minLengthDescription && title.length <= maxLengthTitle && description.length <= maxLengthDescription && folder.length <= maxLengthTitle) {
      setLoading.on();

      const db = getFirestore();

      updateDoc(doc(db, COLLECTION.ORGANIZATIONS, config.ID, COLLECTION.GROUPS, groupId), {
        Title: title.trim(),
        Description: description.trim(),
        Folder: folder.trim(),
      })
        .then(() => {
          onClose();

          toast({
            title: `Updated group`,
            status: "success",
            duration: 2500,
            isClosable: false,
          });

          LogActivityGroup(config.ID, groupId, ACTIVITY.ACTION.UPDATED, ACTIVITY.TYPE.GROUP, { Title: title, Description: description, Folder: folder }, currentUser);
        })
        .catch((e) => {
          console.error(e);

          toast({
            title: "Failed to update group",
            status: "error",
            duration: 2500,
            isClosable: false,
          });
        })
        .finally(() => setLoading.off());
    }
  }

  function deleteGroup() {
    if (Status !== GROUPSTATUS.IDLE) {
      return;
    }

    if (numbers.length > 0) {
      toast({
        title: "Cannot delete group with numbers",
        status: "error",
        duration: 4500,
        isClosable: false,
      });
      return;
    }

    setLoading.on();

    const db = getFirestore();

    updateDoc(doc(db, COLLECTION.ORGANIZATIONS, config.ID, COLLECTION.GROUPS, groupId), {
      Enabled: false,
    })
      .then(() => {
        onClose();

        toast({
          title: `Deleted group`,
          status: "success",
          duration: 2500,
          isClosable: false,
        });

        LogActivityGroup(config.ID, groupId, ACTIVITY.ACTION.DISABLED, ACTIVITY.TYPE.GROUP, null, currentUser);
      })
      .catch((e) => {
        console.error(e);

        toast({
          title: "Failed to delete group",
          status: "error",
          duration: 2500,
          isClosable: false,
        });
      })
      .finally(() => {
        setLoading.off();
      });
  }

  useEffect(() => {
    onClose();
    // eslint-disable-next-line
  }, [Status]);

  useEffect(() => {
    setTitle(Title);
    setDescription(Description ?? "");
    setFolder(Folder ?? "");
    setTitleError(null);
    setDescriptionError(null);
    setFolderError(null);
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered closeOnEsc={!isLoading} closeOnOverlayClick={!isLoading}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={0}>{Title}</ModalHeader>
        <ModalCloseButton isDisabled={isLoading} />
        <ModalBody mb={4}>
          <Text marginBottom={4}>Update group settings.</Text>
          <BrandDivider />
          <FormControl isInvalid={titleError !== null} id="title-input" mt={4} mb={4}>
            <FormLabel>Title</FormLabel>
            <Input type="text" value={title} onChange={handleInputChangeTitle} onBlur={validateTitle} isDisabled={isLoading} placeholder="Group title" size="md" autoFocus />
            <FormErrorMessage>{titleError}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={descriptionError !== null} id="description-input" mt={4} mb={4}>
            <FormLabel>Description</FormLabel>
            <Input type="text" value={description} onChange={handleInputChangeDescription} onBlur={validateDescription} isDisabled={isLoading} placeholder="Group description" size="md" />
            <FormErrorMessage>{descriptionError}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={folderError !== null} id="folder-input" mt={4} mb={4}>
            <FormLabel>Folder</FormLabel>
            <Input type="text" value={folder} onChange={handleInputChangeFolder} onBlur={validateFolder} isDisabled={isLoading} placeholder="Group folder" size="md" />
            <FormErrorMessage>{folderError}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter gap={4}>
          <HStack justifyContent={"space-between"} width={"100%"}>
            <Button variant={"danger"} isDisabled={isLoading} onClick={deleteGroup}>
              Delete group
            </Button>

            <Box>
              <Button onClick={onClose} isDisabled={isLoading} variant={"secondary"} marginEnd={4}>
                Cancel
              </Button>
              <Button onClick={updateGroup} isLoading={isLoading} colorScheme="brand">
                Update group
              </Button>
            </Box>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
