import { Box, Button, Flex, ScaleFade, Text, VStack } from "@chakra-ui/react";
import { faBuildings, faPersonToDoor } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VerticalIconCard } from "components/Miscellaneous/Cards";
import { getAuth } from "firebase/auth";

export default function NoOrganization() {
  const logout = () => {
    const auth = getAuth();
    auth.signOut();
  };

  return (
    <Flex minH={"100vh"} width={"100%"} alignItems={"center"} justifyContent={"center"}>
      <ScaleFade initialScale={0.9} in={true}>
        <VerticalIconCard title={"Organization"} icon={faBuildings}>
          <Text fontSize={"lg"}>No organization found for this user.</Text>
          <VStack spacing={4} width={"100%"} paddingTop={2}>
            <Button variant="secondary" width={"100%"} onClick={logout}>
              Sign out
              <Box position="absolute" right={4}>
                <FontAwesomeIcon icon={faPersonToDoor} aria-hidden="true" />
              </Box>
            </Button>
          </VStack>
        </VerticalIconCard>
      </ScaleFade>
    </Flex>
  );
}
